import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { Button, Card, Input, Modal, Pagination, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import SearchIcon from '@mui/icons-material/Search'
import avatar from '../../../assets/img/avatar.jpg'
import cv from '../../../assets/img/cv.png'
import axiosInstance from '../../../axios/axiosInstance'
import xlsIcon from '../../../assets/img/xls.png'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import CandidatModal from '../CandidatModal'
import CreateCandidatModal from '../CreateCandidatModal'
import FilterCandidat from '../FilterCandidat'
import { Puff } from 'react-loader-spinner'
import { Page, Document } from 'react-pdf'
import AvailableCandidat from '../../AvailableCandidat/AvailableCandidat'

const CandidatList = () => {
  const navigate = useNavigate()
  const [candidates, setCandidates] = useState([])
  const [originalCandidates, setOriginalCandidates] = useState([])
  const [documentCount, setDocumentCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [username, setUsername] = useState('')
  const [isCVTheque, setIsCVTheque] = useState(true)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [isOpenCandidateModal, setIsOpenCandidateModal] = useState(false)
  const [isModalCV, setIsModalCV] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [cvData, setCvData] = useState('')
  const [cvType, setCvType] = useState('')
  const [numPages, setNumPages] = useState([])
  const [isOpenCreateCandidateModal, setIsOpenCreateCandidateModal] =
    useState(false)
  const searchInputRef = useRef(null)

  const fileInputRef = useRef(null)

  const handleButtonClick = (e) => {
    e.preventDefault()
    fileInputRef.current.click() // Programmatically trigger the file input
  }

  const showCv = (e, candidate) => {
    e.stopPropagation()
    const candidateId = candidate._id
    setLoadingSearch(true)
    axiosInstance
      .get(`cv/${candidateId}`)
      .then((res) => {
        console.log(res)
        setCvData(res.data.data)
        const header = res.data.data.substring(0, 5)

        // Check if the file is a PDF, JPEG, or PNG based on the base64 header
        if (header === 'JVBER') {
          setCvType('pdf') // It's a PDF
        } else if (header.startsWith('/9j/')) {
          setCvType('jpeg') // It's a JPEG image
        } else if (header.startsWith('iVBOR')) {
          setCvType('png') // It's a PNG image
        } else {
          console.log('Unsupported file type')
        }
        setSelectedUser(candidate)
        setIsModalCV(true)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingSearch(false))
  }

  const handleExportClick = () => {
    exportToExcel(candidates)
  }

  const exportToExcel = (data) => {
    const newData = data.map((candidate) => {
      const {
        firstname,
        lastname,
        phone,
        email,
        postCode,
        town,
        qualifications,
      } = candidate
      return {
        Nom: lastname,
        Prénom: firstname,
        Téléphone: phone,
        Email: email,
        'Code postal': postCode,
        Ville: town,
        qualifications: qualifications.join(' - '),
      }
    })

    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(newData)

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'data.xlsx')
  }

  useEffect(() => {
    setIsLoading(true)
    const username = localStorage.getItem('name')
    setUsername(username)
    loadCandidates()
  }, [])

  const openCreateCandidateModal = (user) => {
    setSelectedUser(user)
    setIsOpenCreateCandidateModal(true)
  }

  const openCandidateModal = (user) => {
    setSelectedUser(user)
    setIsOpenCandidateModal(true)
  }

  const search = () => {
    if (searchWord.length === 0) {
      setCandidates(originalCandidates)
      setCurrentPage(1)
    }

    setLoadingSearch(true)
    axiosInstance
      .post('tempworker-candidate/find-by-text', { query: searchWord })
      .then((res) => {
        setCandidates(res.data)
        setLoadingSearch(false)
      })
      .catch((e) => setLoadingSearch(false))
  }

  const loadCandidates = () => {
    axiosInstance
      .get('tempworker-candidate/count')
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})

    axiosInstance
      .get('tempworker-candidate')
      .then((res) => {
        setCandidates(res.data)
        let sizeInKB = 0
        for (let x of res.data) {
          const jsonString = JSON.stringify(x)
          const sizeInBytes = new TextEncoder().encode(jsonString).length
          sizeInKB += sizeInBytes / 1024
        }
        console.log(`Array size: ${sizeInKB.toFixed(2)} KB`)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
        setOriginalCandidates(res.data)
      })
      .catch((e) => {
        console.log(e)
        if (e.response && e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        } else {
          message.error("Une erreur s'est produite !")
        }
      })
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const handleDownloadPdf = () => {
    // Generate a temporary anchor element and set the PDF file as its href
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${cvData}`
    link.download = `${selectedUser.firstname}-${selectedUser.lastname}.pdf`

    // Simulate a click event to trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const updateCv = (e) => {
    const formData = new FormData()
    const file = e.target.files[0]
    formData.append('file', file)

    if (file.type === 'application/pdf') {
      axiosInstance
        .post(`cv/${selectedUser._id}/update-cv`, formData)
        .then((res) => {
          console.log(res.data)
          setIsModalCV(false)
          message.success('Cv modifie!')
        })
        .catch((e) => console.log(e))
    } else {
      message.error('Veuillez ajouter un fichier pdf !')
    }
  }

  const onChangePage = (page, pageSize) => {
    setIsLoading(true)
    axiosInstance
      .get(`tempworker-candidate/paginate/${page - 1}`)
      .then((res) => {
        setCandidates(res.data)
        setIsLoading(false)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  return (
    <div>
      {isModalCV && (
        <div className="fixed w-full inset-0 z-30 flex items-center justify-center bg-black bg-opacity-70">
          <div className="flex flex-col items-center justify-start py-6 bg-white rounded-md max-h-[90vh] overflow-y-auto w-full ">
            <div className="flex w-full items-center my-3 justify-center space-x-4">
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={handleDownloadPdf}
              >
                Telechargez le CV
              </p>
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={handleButtonClick}
              >
                Modifier le CV
              </p>
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={() => setIsModalCV(false)}
              >
                Fermer
              </p>
            </div>

            {/* Check if file is PDF or image */}
            {cvData &&
              (cvType === 'pdf' ? (
                // Display PDF using the Document component
                <Document
                  file={`data:application/pdf;base64,${cvData}`}
                  onLoadSuccess={handleDocumentLoadSuccess}
                >
                  {numPages.map((x, idx) => (
                    <Page key={idx} scale={5 / 3} pageNumber={idx + 1} />
                  ))}
                </Document>
              ) : (
                // Display image (JPEG/PNG)
                <img
                  src={`data:image/${cvType};base64,${cvData}`}
                  alt="CV Preview"
                  className="w-full h-auto"
                />
              ))}

            <input
              type="file"
              onChange={(e) => updateCv(e)}
              className="hidden"
              ref={fileInputRef}
            />
          </div>
        </div>
      )}

      {loadingSearch && (
        <div
          className="fixed  inset-0 z-30 bg-gray-400 bg-opacity-40 flex items-center justify-center"
          style={{ top: 0, left: 0 }}
        >
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {isOpenCandidateModal && (
        <CandidatModal
          isOpen={isOpenCandidateModal}
          setIsOpen={setIsOpenCandidateModal}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setCandidates={setCandidates}
          candidates={candidates}
        />
      )}
      {isOpenFilter && !isOpenCandidateModal && (
        <FilterCandidat
          originalCandidates={originalCandidates}
          setCandidates={setCandidates}
          open={isOpenFilter}
          setIsOpen={setIsOpenFilter}
        />
      )}
      {isOpenCreateCandidateModal && (
        <CreateCandidatModal
          candidates={candidates}
          setCandidates={setCandidates}
          isOpen={isOpenCreateCandidateModal}
          setIsOpen={setIsOpenCreateCandidateModal}
          openCandidateModal={openCandidateModal}
        />
      )}

      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setIsCVTheque(true)}
          className={`${
            isCVTheque ? 'bg-white border border-gray-200' : ''
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">CVthèque</p>
        </div>

        <div
          onClick={() => setIsCVTheque(false)}
          className={`${
            !isCVTheque ? 'bg-white border border-gray-200' : ''
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">Candidats disponibles</p>
        </div>
      </div>

      {isCVTheque && (
        <div>
          <div className="my-6">
            <p className="text-2xl font-semibold ml-2">
              {documentCount} Candidats
            </p>
          </div>
          <div className="text-left flex space-x-1 my-10 h-10 items-center w-full justify-end pr-10">
            <div className="flex items-center justify-center space-x-2">
              <Input
                ref={searchInputRef}
                className={`w-80  rounded-md border border-gray-200 focus:outline-none`}
                placeholder="Recherche"
                style={{ outline: 'none' }}
                onChange={(e) => setSearchWord(e.target.value)}
              />
              <button
                onClick={() => search()}
                className="bg-green-500 text-white p-2 rounded-md"
              >
                Rechercher
              </button>
            </div>
            {/* <SearchIcon
                  onClick={() => showSearchInput(showSearch)}
                  className="text-gray-500 text-3xl hover:cursor-pointer"
                /> */}

            <FilterListOutlinedIcon
              className="w-8 h-8 text-gray-500 hover:cursor-pointer"
              onClick={() => setIsOpenFilter(!isOpenFilter)}
            />
            <img
              src={xlsIcon}
              onClick={handleExportClick}
              className="w-8 h-8 mx-2 hover:cursor-pointer"
            />
            <AddCircleOutlineIcon
              className="w-8 h-8 text-gray-500 hover:cursor-pointer"
              onClick={openCreateCandidateModal}
            />
          </div>

          {candidates.length > 0 && (
            <div className="p-4">
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                {candidates.map((candidate, key) => (
                  <Card
                    onClick={() => openCandidateModal(candidate)}
                    key={key}
                    className="bg-white border border-gray-300 rounded-md overflow-hidden hover:cursor-pointer "
                    loading={isLoading}
                  >
                    <div className="flex flex-col justify-between h-full p-4">
                      <div>
                        <div className="flex items-start justify-between mb-4">
                          <div className="flex items-center space-x-3">
                            <img
                              src={
                                candidate.image
                                  ? `data:image/png;base64,${candidate.image}`
                                  : avatar
                              }
                              alt={`${candidate.firstname} ${candidate.lastname}`}
                              className="w-14 h-14 rounded-full"
                            />
                            <div>
                              <p className="font-bold text-lg capitalize">
                                {candidate.firstname.toLowerCase()}{' '}
                                {candidate.lastname.toLowerCase()}
                              </p>
                              {candidate.pld && (
                                <p className="text-red-500 text-sm">
                                  #{candidate.pld}
                                </p>
                              )}
                            </div>
                          </div>
                          <img
                            src={cv}
                            alt="CV"
                            className="w-10 h-10 cursor-pointer"
                            onClick={(e) => showCv(e, candidate)}
                          />
                        </div>

                        <div className="flex items-center my-4 space-x-2">
                          <PhoneOutlined className="text-blue-500 text-lg" />
                          <p className="font-semibold">
                            {candidate.phone.replace(/(\d{2})(?=\d)/g, '$1 ')}
                          </p>
                        </div>

                        <div className="flex items-center mb-4 space-x-2">
                          <MailOutlined className="text-blue-500 text-lg" />
                          <p className="font-semibold uppercase">
                            {candidate.email}
                          </p>
                        </div>

                        <div className="flex items-center mb-4 space-x-2">
                          <LocationOnOutlinedIcon className="text-blue-500 text-xl" />
                          <p className="font-semibold uppercase">
                            {candidate.postCode} {candidate.town?.toUpperCase()}
                          </p>
                        </div>

                        <div className="flex items-start mb-4 space-x-2">
                          <WorkOutlineOutlinedIcon className="text-blue-500 text-xl" />
                          <div className="flex flex-col">
                            <p className="font-semibold uppercase">
                              {candidate.qualifications[0]}
                            </p>
                            {/* Render more qualifications if needed */}
                          </div>
                        </div>
                      </div>

                      {candidate.status && (
                        <div className="flex justify-center mt-4">
                          <span
                            className={`text-white text-xs rounded-md text-center px-2 py-1 ${
                              candidate.status === 'Indisponible'
                                ? 'bg-orange-400'
                                : candidate.status === 'Bloqué'
                                ? 'bg-black'
                                : 'bg-green-500'
                            }`}
                          >
                            {candidate.status}
                          </span>
                        </div>
                      )}
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}

          <div className="text-right">
            <Pagination
              className="mt-6 w-full"
              current={currentPage}
              onChange={onChangePage}
              total={documentCount}
              pageSize={48}
            />
          </div>
        </div>
      )}
      {!isCVTheque && <AvailableCandidat />}
    </div>
  )
}

export default CandidatList
