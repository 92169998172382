import { Input, Modal, Steps, message } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../../axios/axiosInstance'
import CreateContractCandidate from './CreateContractCandidate'
import CreateContractMotif from './CreateContractMotif'
import CreateContractDate from './CreateContractDate'
import CreateContractLieu from './CreateContractLieu'
import CreateContractPoste from './CreateContractPoste'
import CreateContractTask from './CreateContractTask'
import CreateContractConsigne from './CreateContractConsigne'
import CreateContractPenibilite from './CreateContractPenibilite'
import CreateContractContact from './CreateContractContact'
import CreateContractReference from './CreateContractReference'
import CreateContractPeriodeEssai from './CreateContractPeriodeEssai'

const CreateContract2 = ({
  client,
  qualifications,
  chantiers,
  primes,
  isCreateContract,
  setIsCreateContract,
}) => {
  const [current, setCurrent] = useState(0)
  const [contract, setContract] = useState({
    clientPld: client.pld,
  })
  const [isCreating, setIsCreating] = useState(false)

  const next = () => {
    if (current === 0) {
      if (!contract.contact) {
        message.error('Veuillez selectionnez un contact')
        return
      }
    }
    if (current === 1) {
      if (!contract.candidate) {
        message.error('Veuillez selectionnez un candidat')
        return
      }
    }
    if (current === 2) {
      if (!contract.motif) {
        message.error('Veuillez selectionnez un motif')
        return
      }
      if (!contract.justificatif) {
        message.error('Veuillez selectionnez un justificatif')
        return
      }
    }
    if (current === 3) {
      if (!contract.startDate) {
        message.error('Veuillez selectionnez une date de debut !')
        return
      }
      if (!contract.endDate) {
        message.error('Veuillez selectionnez une date de fin !')
        return
      }
      if (!contract.startHour) {
        message.error('Veuillez selectionnez une heure de debut !')
        return
      }
      if (!contract.endHour) {
        message.error('Veuillez selectionnez une heure de fin !')
        return
      }
      if (!contract.nbHeureHebdo) {
        message.error(
          "Veuillez selectionnez le nombre d'heures hebdomadaires !",
        )
        return
      }
    }
    if (current === 4) {
      if (!contract.clientName) {
        message.error('Veuillez entrer le nom du client !')
        return
      }
      if (!contract.address) {
        message.error("Veuillez entrer l'adresse du client !")
        return
      }
      if (!contract.postCode) {
        message.error('Veuillez entrer le code postal du client !')

        return
      }
      if (!contract.town) {
        message.error('Veuillez entrer la ville du client !')

        return
      }
      if (!contract.EstChezClient) {
        message.error('Veuillez selectionner le chantier !')

        return
      }
      if (!contract.EstBTP) {
        message.error('Veuillez selectionner le BTP !')

        return
      }
      if (!contract.EstSansTaxeTransport) {
        message.error('Veuillez selectionner la taxe transport !')

        return
      }
    }
    if (current === 5) {
      if (!contract.clientQualification) {
        message.error('Veuillez selectionner un poste !')
        return
      }
    }
    if (current === 6) {
      if (!contract.consigne) {
        message.error('Veuillez selectionner une consigne !')
        return
      }
    }
    if (current === 7) {
      if (!contract.task) {
        message.error('Veuillez entrer les tâches !')
        return
      }
    }
    if (current === 9) {
      if (!contract.NbJourEssai) {
        message.error("Veuillez entrer le nombre de jour d'essai !")
        return
      }
    }
    if (current === 10) {
      if (!contract.PenibiliteNC) {
        message.error('Veuillez entrer les pénébilités !')
      }
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  useEffect(() => {
    console.log(client)
  }, [])

  const formatJsHour = (date) => {
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0') // Months are 0-based, so we add 1

    return `${hours}${minutes}`
  }

  const formatJsDateTempo = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${year}${month}${day}`
  }

  const validateContract = () => {
    setIsCreating(true)

    contract.startHourString = formatJsHour(contract.startHour)
    contract.endHourString = formatJsHour(contract.endHour)
    contract.startDateString = formatJsDateTempo(contract.startDate)
    contract.endDateString = formatJsDateTempo(contract.endDate)

    axiosInstance
      .post('pld-contract', contract)
      .then((res) => {
        console.log(res.data.error)
        const error = res.data.error
        if (error === undefined) {
          message.success('Contrat créé ! ')
          setIsCreateContract(false)
        } else message.error(error.message)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
      .finally(() => {
        setIsCreating(false)
      })
  }

  const steps = [
    {
      title: 'Contact',
      content: (
        <CreateContractContact
          client={client}
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Candidat',
      content: (
        <CreateContractCandidate
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Motif',
      content: (
        <CreateContractMotif contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Dates de la mission',
      content: (
        <CreateContractDate contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Lieu de la misson',
      content: (
        <CreateContractLieu
          client={client}
          chantiers={chantiers}
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Poste',
      content: (
        <CreateContractPoste
          qualifications={qualifications}
          primes={primes}
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Consignes particulières',
      content: (
        <CreateContractConsigne contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Tâches / missions',
      content: (
        <CreateContractTask contract={contract} setContract={setContract} />
      ),
    },
    {
      title: 'Reference / Horaires particuliers',
      content: (
        <CreateContractReference
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: "Période d'essai",
      content: (
        <CreateContractPeriodeEssai
          contract={contract}
          setContract={setContract}
        />
      ),
    },
    {
      title: 'Pénibilités',
      content: (
        <CreateContractPenibilite
          contract={contract}
          setContract={setContract}
        />
      ),
    },
  ]

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  return (
    <Modal
      footer={null}
      width={1000}
      className='h-[800px]'
      open={isCreateContract}
      onCancel={() => setIsCreateContract(false)}
    >
      <div className="flex items-start mt-10 space-x-4">
        <Steps
          direction="vertical"
          className="w-2/6"
          current={current}
          items={items}
        />

        <div className="w-4/6 h-[800px]  flex flex-col justify-between">
          <div>{steps[current].content}</div>
          <div className="flex items-center space-x-2 justify-end ">
            {current > 0 && (
              <button
                className="bg-white text-black p-2 rounded-md border border-gray-300"
                onClick={() => prev()}
              >
                Precedent
              </button>
            )}

            {current === steps.length - 1 && (
              <button
                className="bg-[#6022ea] p-2 rounded-md text-white"
                disabled={isCreating}
                onClick={() => validateContract()}
              >
                {!isCreating && <span>Valider le contrat</span>}
                {isCreating && <span>...Creation</span>}
              </button>
            )}
            {current < steps.length - 1 && (
              <button
                className="bg-[#6022ea] p-2 rounded-md text-white"
                onClick={() => next()}
                type="primary"
              >
                Suivant
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateContract2
