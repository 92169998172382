import PersonIcon from '@mui/icons-material/Person'
import { Checkbox, Input, Switch } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import sun from '../../assets/img/sun-74.png'
import moon from '../../assets/img/moon.jpeg'
import download from '../../assets/img/download-icon.jpg';
import TextArea from 'antd/es/input/TextArea'

const RHItem = ({ item, daysArray }) => {
  const [rh, setRh] = useState(item)
  const [heureMinute,setHeureMinute] = useState(false);
  const [value,setValue] = useState('');
  const [times,setTimes] = useState(['','','','','','','','','','','','','','']);


  useEffect(()=> {


  },[]);

  const createRh = () => {

    axiosInstance
      .post('releve-heures', {
        ...rh
      })
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e))
  } 

  
  /* const updateHours = (idx, value) => {
    const newTabJours = tabJours.map((jour) => {
      if (jour.NumeroJour === idx) {
        return { ...jour, NbHeureJour: value }
      }
      return jour
    })

    setTabJours(newTabJours)
  } */

 

  function formatDate(date) {
    const year = date.substring(0, 4)
    const month = date.substring(4, 6)
    const day = date.substring(6, 8)
    return `${day}/${month}/${year}`
  }

  

   // Fonction pour convertir HH:MM en décimal (ex: 2h30 -> 2.5)
   const convertHHMMToDecimal = (hhmm) => {
    const [hours, minutes] = hhmm.split(':').map(Number);
    return hours + minutes / 60;
  };

  // Fonction pour convertir décimal en HH:MM (ex: 2.5 -> 2h30)
  const convertDecimalToHHMM = (decimal) => {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  // Gestion du changement de l'input
  const handleInputChange = (idx,e,d) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, ''); 

        const newRh = {...rh};
        const jourIndex = newRh.TabJours.findIndex((jour)=> jour.NumeroJour === idx+1)
        console.log(jourIndex)
        if(d==='d')
        newRh.TabJours[jourIndex].NbHeureJour = inputValue;
        else
        newRh.TabJours[jourIndex].NbHeureNuit = inputValue;

        setRh(newRh);

        axiosInstance.patch(`releve-heures/${newRh._id}`,newRh)
        .then((res=>console.log(res)))
        .catch(e=> console.log(e));
  };

  // Changer le mode et convertir la valeur si nécessaire
  const toggleMode = () => {
    if (!heureMinute) {
      // Passer en mode HH:MM et convertir la valeur actuelle
      const decimalValue = parseFloat(value) || 0;
      setValue(convertDecimalToHHMM(decimalValue));
      setHeureMinute(true);

    } else {
      // Passer en mode décimal et convertir la valeur actuelle
      const hhmmValue = value || '00:00';
      setValue(convertHHMMToDecimal(hhmmValue).toString());
      setHeureMinute(false);
    }
  };


  const handleTimeChange = (idx,e,d,timeIndex) => {
    let input = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters

    if (input.length === 1 && parseInt(input, 10) > 2) {
      input = '0' + input;
    }
    // Allow user to delete characters
    if (input.length <= 4) {
      // Add colon after two digits if input length is greater than 2
      if (input.length > 2) {
        input = input.slice(0, 2) + ':' + input.slice(2);
      }

      // Only apply max limits for hours and minutes if input has at least 4 digits
      if (input.length === 5) {
        let hours = Math.min(parseInt(input.slice(0, 2), 10), 23).toString().padStart(2, '0');
        let minutes = Math.min(parseInt(input.slice(3, 5), 10), 59).toString().padStart(2, '0');
        input = `${hours}:${minutes}`;
      }
    }
    const newTimes = [...times];
    newTimes[timeIndex] = input;
    if(input.length===5){
      const newRh = {...rh};
      const jourIndex = newRh.TabJours.findIndex((jour)=> jour.NumeroJour === idx+1)

      if(d==='d')
        newRh.TabJours[jourIndex].NbHeureJour = convertHHMMToDecimal(input);
        else
        newRh.TabJours[jourIndex].NbHeureNuit = convertHHMMToDecimal(input);
        axiosInstance.patch(`releve-heures/${newRh._id}`,newRh)
        .then((res=>console.log(res)))
        .catch(e=> console.log(e));
      setRh(newRh)
    }
    
    setTimes(newTimes);
  };

  return (

    <div>
      <div className='flex items-center space-x-2'>
        <div className='flex items-center space-x-2'>
        <Switch onChange={()=> toggleMode()} checked={heureMinute} className={`${heureMinute?'bg-blue-600':'bg-gray-300'}`} />
        <p>HEURES : MINUTES</p>
        </div>


      {/*   <div className='flex items-center space-x-1'>
        <Switch className='bg-gray-300' />
        <p>test</p>
        </div>


        <div className='flex items-center space-x-1'>
        <Switch className='bg-gray-300' />
        <p>test</p>
        </div>


        <div className='flex items-center space-x-1'>
        <Switch className='bg-gray-300' />
        <p>test</p>
        </div>
 */}

      </div>
    <div className=" bg-white text-[10px] my-4 border border-gray-300 rounded-lg hover:scale-101 shadow-xl p-4 transition-all duration-300">

      
      <div className="flex items-center">
        <p className="my-4 text-blue-700 text-base">
          {formatDate(item?.DateDebut)} - {formatDate(item?.DateFin)}
        </p>
      </div>
      <div className="flex items-center">
        <div className="w-4/12 flex space-x-4">
          <div className="h-16 w-16 rounded-full border border-yellow-400 flex items-center justify-center">
            <PersonIcon />
          </div>

          <div className="">
            <p className="font-bold mb-2">{item.NomPrenom}</p>
            <div>
              <p>{item.RaisonSociale.substring(0, 20)}...</p>
              <p className="text-grayy-400">
                {item.NumeroContrat}_{item.NumeroAvenant}
              </p>
            </div>
          </div>
        </div>

        <div className="flex h-24 items-center w-8/12 justify-between text-xs space-x-3">
          <div className="flex h-full flex-col space-y-3">
            <p className='h-1/3'></p>
            <p className="flex h-1/3 items-center justify-center">
              <img className="w-6" src={sun} />
            </p>
            <p className="flex h-1/3 items-center justify-center">
              <img className="w-5" src={moon} />
            </p>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[0]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(0,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===1)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===1)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 1)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(0,e,'d',0)}
              value={times[0]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 1)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(0,e,'n')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===1)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===1)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 1)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(0,e,'n',1)}
              value={times[1]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 1)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <div className="flex flex-col space-y-1">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[1]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(1,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===2)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===2)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 2)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(1,e,'d',2)}
              value={times[2]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 2)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(1,e,'n')}
              vvalue={rh.TabJours.findIndex((jour)=> jour.NumeroJour===2)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===2)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 2)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(1,e,'n',3)}
              value={times[3]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 2)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <div className="flex flex-col space-y-1">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[2]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>

            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(2,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===3)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===3)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 3)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(2,e,'d',4)}
              value={times[4]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 3)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(2,e,'n')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===3)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===3)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 3)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(2,e,'n',5)}
              value={times[5]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 3)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <div className="flex flex-col space-y-1 items-center justify-center">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[3]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(3,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===4)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===4)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 4)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(3,e,'d',6)}
              value={times[6]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 4)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(3,e,'n')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===4)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===4)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 4)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(3,e,'n',7)}
              value={times[7]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 4)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <div className="flex flex-col space-y-1">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[4]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>

            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(4,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===5)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===5)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 5)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(4,e,'d',8)}
              value={times[8]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 4)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(4,e,'n')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===5)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===5)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 5)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(4,e,'n',9)}
              value={times[9]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 5)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <div className="flex flex-col space-y-1">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[5]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>

            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(5,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===6)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===6)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 6)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(5,e,'d',10)}
              value={times[10]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 6)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(5,e,'n')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===6)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===6)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 6)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(5,e,'n',11)}
              value={times[11]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 6)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <div className="flex flex-col space-y-1">
            <p className="text-xs flex flex-col items-center justify-center">
              {daysArray[6]?.split(' ').map((e) => (
                <span>{e}</span>
              ))}
            </p>
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(6,e,'d')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===7)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===7)].NbHeureJour}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 7)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(6,e,'d',12)}
              value={times[12]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 7)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {!heureMinute &&<Input
              onChange={(e) => handleInputChange(6,e,'n')}
              value={rh.TabJours.findIndex((jour)=> jour.NumeroJour===7)===-1?'':rh.TabJours[rh.TabJours.findIndex((jour)=> jour.NumeroJour===7)].NbHeureNuit}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 7)}
              className="w-14 px-2  text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
            {heureMinute &&<Input
              onChange={(e) => handleTimeChange(6,e,'n',13)}
              value={times[13]}
              disabled={!rh.TabJours.some((jour) => jour.NumeroJour === 7)}
              className="w-14 px-2 text-xs rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
              placeholder={heureMinute?"00:00":'0'}
            />}
          </div>

          <button
            onClick={()=> createRh()}
            className="bg-mountain-500 text-white p-2 rounded-md"
          >
            Envoyer vers tempo
          </button>
          <p></p>
        </div>
      </div>
      <div className="h-10 mt-10 bg-gray-200 rounded-md flex items-center justify-end px-4">
        <p><img className='w-8' src={download} /></p>
      </div>

      <div className='flex flex-col items-start justify-start mt-10 space-y-2'>
        <div className='w-1/4'>
        <p className='font-semibold text-md mb-2'>Commentaire du client</p>
        <TextArea disabled={true} className='w-4/4' />
        </div>
        <div className='w-1/4'>
        <p className='font-semibold text-md mb-2'>Commentaire de l'interimaire</p>
        <TextArea disabled={true} className='w-4/4' />
        </div>
      </div>
    </div>
    </div>

  )
}

export default RHItem
