import { useEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import axios from '../../../axios/axiosInstance'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { CalendarMonth } from '@mui/icons-material'
import jsPDF from 'jspdf'

import { createPdf } from '../../utils/pdfUtils'
import { Button, Pagination, Popover } from 'antd'

const ClientModalContract = ({
  client,
  setSelectedContract,
  setIsDuplicateContract,
}) => {
  const [contracts, setContracts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [documentCount, setDocumentCount] = useState(0)

  const loadDocumentCount = () => {
    if (!client.pld) return

    axios
      .get(`pld-contract/admin/count/${client.pld}`)
      .then((res) => {
        setDocumentCount(res.data)
        console.log(res.data)
      })
      .catch((e) => {})
  }

  useEffect(() => {
    loadDocumentCount()
    loadContract()
  }, [])

  const onChangePage = (page, pageSize) => {
    if (!client.pld) return
    axios
      .get(`pld-contract/admin/paginate/${page - 1}/${client.pld}`)
      .then((res) => {
        console.log(res.data)
        setContracts(res.data)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const generatePDF = async (contract) => {
    if (!contract.signedByUser) {
      const pdf = new jsPDF({
        unit: 'mm',
        format: [210, 297],
      })
      try {
        const pdfFile = await createPdfFile(contract, pdf)
        const pdfBlob = pdfFile.output('blob')
        const pdfUrl = URL.createObjectURL(pdfBlob)
        console.log(pdfUrl)
        window.open(pdfUrl, '_blank')
      } catch (e) {
        console.log(e)
      }
    } else {
    }
  }
  const loadContract = () => {
    console.log(client.pld)
    if (!client.pld) return
    axios
      .get(`pld-contract/admin/${client.pld}`)
      .then((res) => {
        console.log(res.data)
        setContracts(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const formatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6, 8)

    return `${day}/${month}/${year}`
  }

  const createPdfFile = async (contract, pdf) => {
    return await createPdf(contract, pdf)
  }

  return (
    <div className="bg-cyan-100 h-[600px] overflow-scroll pt-6">
      <div className="flex items-center justify-between  mb-6 mx-20 text-xs">
        <p className="w-1/5 text-center  animate-fadeIn">N° DE CONTRAT</p>
        <p className="w-1/5 text-center">ENTREPRISE</p>
        <p className="w-1/5 text-center">INTERIMAIRE</p>
        <p className="w-1/5 text-center">DATE DE CONTRAT</p>
        <p className="w-1/5 text-center">ACTION</p>
      </div>

      <div className="flex flex-col space-y-4">
        {contracts.map((contract, idx) => {
          return (
            <div
              key={idx}
              className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between mx-20 bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
            >
              <div className="w-1/5 flex flex-col items-center">
                <p className="font-bold">J'M INTERIM</p>
                <p className="text-gray-500">Asnieres sur seine</p>
                <p className="text-gray-500">{contract.IdContrat}</p>
              </div>

              <div className="w-1/5 flex items-center justify-center space-x-2">
                <div>
                  <p>
                    <LocationOnIcon className="text-3xl text-gray-300" />
                  </p>
                </div>
                <div className="text-xs">
                  <p className="font-bold">{contract.RaisonSociale}</p>
                  <p className="text-gray-500">
                    {' '}
                    {contract.AdresseRue} - {contract.AdresseCodePostal}
                  </p>
                  <p className="text-gray-500">{contract.AdresseVille}</p>
                </div>
              </div>

              <div className="w-1/5 flex items-center justify-center space-x-2">
                <AccountBoxIcon className="text-3xl text-gray-300" />
                <div>
                  <p className="font-bold text-xs">{contract.NomPrenom}</p>
                  <p className="text-xs text-gray-500">{contract.Profession}</p>
                </div>
              </div>

              <div className="w-1/5 flex items-center space-x-2 justify-center">
                <div>
                  <p>
                    <CalendarMonth className="text-3xl text-gray-300" />
                  </p>
                </div>
                <div className="text-xs">
                  <p className="font-bold text-right">
                    Du {formatDate(contract.DateDebut)}
                  </p>
                  <p className="font-bold text-right">
                    au {formatDate(contract.DateFin)}
                  </p>
                </div>
              </div>

              <div className="w-1/5 flex items-center space-x-2 justify-center">
                <Popover
                  placement="bottom"
                  content={
                    <div className="flex flex-col capitalize text-base space-y-3">
                      <p
                        onClick={() => generatePDF(contract)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Visualiser</span>
                      </p>
                      <p
                        onClick={() => generatePDF(contract)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Télécharger</span>
                      </p>
                      <p
                        onClick={() => {
                          setSelectedContract(contract)
                          setIsDuplicateContract(true)
                        }}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Dupliquer</span>
                      </p>
                    </div>
                  }
                >
                  <Button>...</Button>
                </Popover>
              </div>

              {/*   <div className="w-1/5 flex justify-center items-center">
                <p
                  className={`hidden  text-gray-500 p-1 rounded-md text-sm w-fit group-hover:flex items-center space-x-2 group-hover:animate-fadeIn`}
                >
                  <SearchIcon  />
                  <SimCardDownloadIcon onClick={() => downloadPdf(contract)} />
                </p>
              </div> */}
            </div>
          )
        })}
      </div>
      <div className="text-right mr-10">
        <Pagination
          className="mt-6 w-full mb-6 "
          total={documentCount}
          current={currentPage}
          onChange={onChangePage}
          pageSize={20}
        />
      </div>
    </div>
  )
}

export default ClientModalContract
