import { Checkbox, Input, Modal, Select, Switch, message } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { EditOutlined } from '@ant-design/icons'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useNavigate } from 'react-router-dom'

const UserCreate = () => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [phone, setPhone] = useState('')
  const [users, setUsers] = useState([])
  const [openCreateUser, setOpenCreateUser] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    loadUsers()
  }, [])

  const loadUsers = () => {
    axiosInstance
      .get('user')
      .then((res) => {
        setUsers(res.data)
        console.log(res.data)
      })
      .catch((e) => console.log(e))
  }

  const openCreateModal = () => {
    setOpenCreateUser(true)
  }

  const createUser = () => {
    if (
      !firstname.length ||
      !lastname.length ||
      !email.length ||
      !username.length ||
      !phone.length ||
      !role.length
    ) {
      message.error('Veuillez remplir tous les champs!')
      return
    }

    axiosInstance
      .post('auth/admin', {
        phone,
        firstname,
        lastname,
        email,
        username,
        role,
      })
      .then((res) => {
        message.success('Utilisateur crée')
        setEmail('')
        setFirstname('')
        setLastname('')
        setUsername('')
        setPhone('')
      })
      .catch((e) => {
        console.log(e)
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const updateRole = (user, e) => {
    axiosInstance
      .post(`user/${user._id}/update-role`, { role: e })
      .then((res) => {
        const newUsers = [...users]
        newUsers[users.findIndex((user) => user._id === res.data._id)] =
          res.data
        setUsers(newUsers)
        message.success('Mise à jour utilisateur !')
      })
  }

  const updateStatus = (user) => {
    const status = user.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
    axiosInstance
      .post(`user/${user._id}/update-status`, { status })
      .then((res) => {
        console.log(res.data)
        const newUsers = [...users]
        newUsers[users.findIndex((user) => user._id === res.data._id)] =
          res.data
        setUsers(newUsers)
        message.success('Mise à jour utilisateur !')
      })
  }

  return (
    <div>
      <Modal
        footer={null}
        open={openCreateUser}
        onCancel={() => setOpenCreateUser(false)}
      >
        <div className="flex flex-col space-y-3 items-center">
          <Input
            onChange={(e) => setFirstname(e.target.value)}
            type="text"
            value={firstname}
            className="border border-gray-300 rounded-md w-2/4"
            placeholder="Prénom"
          />
          <Input
            onChange={(e) => setLastname(e.target.value)}
            type="text"
            value={lastname}
            className="border border-gray-300 rounded-md w-2/4"
            placeholder="Nom"
          />
          <Input
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            value={username}
            className="border border-gray-300 rounded-md w-2/4"
            placeholder="Username"
          />
          <Input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            value={email}
            className="border border-gray-300 rounded-md w-2/4"
            placeholder="Email"
          />
          <Input
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            value={phone}
            className="border border-gray-300 rounded-md w-2/4"
            placeholder="Téléphone"
          />
          <Select
            onChange={(e) => setRole(e)}
            className="w-2/4"
            placeholder="Role"
            options={[
              { key: 'Admin', value: 'ADMIN', label: 'Admin' },
              { key: 'Superadmin', value: 'SUPERADMIN', label: 'Superadmin' },
              { key: 'Freelance', value: 'FREELANCE', label: 'Freelance' },
            ]}
          />
          <button
            onClick={createUser}
            className="text-green-400 bg-green-50 border border-green-300 p-2 rounded-md"
          >
            Créer l'utilisateur{' '}
          </button>
        </div>
      </Modal>

      <div>
        <p className="my-6 text-right " onClick={openCreateModal}>
          <AddCircleOutlineIcon className="hover:cursor-pointer" />
        </p>
        <div className="flex items-center uppercase text-xs justify-center">
          <p className="w-1/6 text-center">Nom</p>
          <p className="w-1/6 text-center">Username</p>
          <p className="w-1/6 text-center">Role</p>
          <p className="w-1/6 text-center">Email</p>
          <p className="w-1/6 text-center">Téléphone</p>
          <p className="w-1/6 text-center">Statut</p>
        </div>
        <div className="mt-10 flex flex-col border border-gray-300">
          {users.map((user) => {
            return (
              <div className="flex items-center  text-sm justify-between  bg-white   py-2 px-2 border-b border-b-gray-300 hover:cursor-pointer ">
                <p className="w-1/6 text-center">
                  {user.firstname} {user.lastname}
                </p>
                <p className="w-1/6 text-center">{user.username}</p>
                <p className="w-1/6 flex justify-center">
                  <Select
                    className="w-2/4"
                    value={user.role}
                    optionFilterProp="children"
                    onChange={(e) => updateRole(user, e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        label: 'ADMIN',
                        value: 'ADMIN',
                        key: 'ADMIN',
                      },
                      {
                        label: 'SUPERADMIN',
                        value: 'SUPERADMIN',
                        key: 'SUPERADMIN',
                      },
                    ]}
                  />
                </p>
                <p className="w-1/6 text-center">{user.email}</p>
                <p className="w-1/6 text-center">{user.phone}</p>
                <p className="w-1/6 flex items-center justify-center">
                  <Switch
                    className={`${
                      user.status === 'ACTIVE' ? 'bg-blue-500' : 'bg-gray-400'
                    }`}
                    onChange={() => updateStatus(user)}
                    checkedChildren="ACTIF"
                    unCheckedChildren="INACTIF"
                    checked={user.status === 'ACTIVE'}
                  />

                  {/* <Select
                    className="w-4/5"
                    value={user.status}
                    optionFilterProp="children"
                    onChange={(e) => updateStatus(user, e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        label: 'ACTIVE',
                        value: 'ACTIVE',
                        key: 'ACTIVE',
                      },
                      {
                        label: 'INACTIVE',
                        value: 'INACTIVE',
                        key: 'INACTIVE',
                      },
                    ]}
                  /> */}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default UserCreate
