import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import axios from '../../axios/axiosInstance'
import { useEffect, useRef, useState } from 'react'
import { AddCircleOutlineOutlined, CalendarMonth } from '@mui/icons-material'
import jsPDF from 'jspdf'
import {
  Button,
  Checkbox,
  Input,
  Popconfirm,
  Popover,
  Modal,
  Spin,
  message,
} from 'antd'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { createPdf, createVacationPdf } from '../utils/pdfUtils'
import { Pagination } from 'antd'
import xlsIcon from '../../assets/img/xls.png'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { Puff } from 'react-loader-spinner'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

export default function ClientContract() {
  const [contracts, setContracts] = useState([])
  const [originalContracts, setOriginalContracts] = useState([])
  const [vacationContracts, setVacationContracts] = useState([])
  const [originalVacationContracts, setOriginalVacationContracts] = useState([])
  const [loadingContract, setLoadingContract] = useState(false)
  const [isInterim, setIsInterim] = useState(true)
  const [isVacation, setIsVacation] = useState(false)
  const [documentCount, setDocumentCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchWord, setSearchWord] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [isCancelDate, setIsCancelDate] = useState(false)
  const [selectedContract, setSelectedContract] = useState(null)
  const [isClientCancelling, setIsClientCancelling] = useState(false)

  const pdfRef = useRef(null)
  const navigate = useNavigate()

  const search = () => {
    if (searchWord.length < 2) {
      message.error('Minimum trois caracteres !')
      return
    }
    setIsSearch(true)
    setLoadingSearch(true)
    let target = isInterim ? 'pld-contract' : 'contract'

    axios
      .post(`${target}/find-by-text`, { query: searchWord })
      .then((res) => {
        console.log(res.data)
        if (target === 'contract') setVacationContracts(res.data)
        else setContracts(res.data)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingSearch(false))
  }

  const formatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6, 8)

    return `${day}/${month}/${year}`
  }

  const selectSearchWord = (word) => {
    if (word.length === 0) {
      setContracts(originalContracts)
      setVacationContracts(originalVacationContracts)
      setCurrentPage(0)
      setIsSearch(false)
    }

    setSearchWord(word)
  }

  const deleteContract = (e, contractId) => {
    e.stopPropagation()
    axios
      .delete(`contract/${contractId}`)
      .then((res) => {
        const contractIdx = vacationContracts.findIndex(
          (cntr) => cntr._id === contractId,
        )
        const newContracts = [...vacationContracts]
        newContracts.splice(contractIdx, 1)
        setVacationContracts(newContracts)
        message.success('Contrat supprimé !')
      })
      .catch((e) => {
        console.log(e)
        message.error("Vous n'etes pas autorisé à supprimer le contract !")
      })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const generatePDF = async (contract) => {
    const pdf = new jsPDF({
      unit: 'mm',
      format: [210, 297],
    })
    try {
      const pdfFile = await createPdfFile(contract, pdf)
      console.log('dodododod')
      console.log(pdfFile)
      const pdfBlob = pdfFile.output('blob')
      const pdfUrl = URL.createObjectURL(pdfBlob)
      console.log(pdfUrl)
      window.open(pdfUrl, '_blank')
      setLoadingContract(false)
    } catch (e) {
      console.log(e)
      setLoadingContract(false)
    }
  }

  const generateVacationPDF = async (contract) => {
    const pdf = new jsPDF({
      unit: 'mm',
      format: [210, 297],
    })
    console.log(contract)
    try {
      const pdfFile = await createVacationPdfFile(contract, pdf)
      const pdfBlob = pdfFile.output('blob')
      const pdfUrl = URL.createObjectURL(pdfBlob)
      console.log(pdfUrl)
      window.open(pdfUrl, '_blank')
    } catch (e) {
      console.log(e)
      message.error("Une erreur s'est produite !")
    }
    setLoadingContract(false)
  }

  const createPdfFile = async (contract, pdf) => {
    setLoadingContract(true)
    return await createPdf(contract, pdf)
  }

  const createVacationPdfFile = async (contract, pdf) => {
    setLoadingContract(true)
    return await createVacationPdf(contract, pdf)
  }

  useEffect(() => {
    axios
      .get('pld-contract/count')
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})

    loadContracts()
    loadVacationContracts()
  }, [])

  const onChangePage = (page, pageSize) => {
    setLoadingSearch(true)

    let target = isInterim ? 'pld-contract' : 'contract'
    axios
      .get(`${target}/paginate/${page - 1}`)
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional: smooth scrolling animation
        })
        if (target === 'contract') setVacationContracts(res.data)
        else setContracts(res.data)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoadingSearch(false)
      })
  }

  const dematContract = (contractId) => {
    axios
      .patch(`pld-contract/demat/${contractId}`, { isDemat: true })
      .then((res) => {
        console.log(res.data)
        message.success('Contrat dematerialisé !')
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
      })
  }

  const switchToInterim = (isInterim) => {
    if (isInterim) {
      setIsInterim(true)
      setIsVacation(false)
      axios
        .get('pld-contract/count')
        .then((res) => setDocumentCount(res.data))
        .catch((e) => {})
    } else {
      setIsInterim(false)
      setIsVacation(true)
      axios
        .get('contract/count')
        .then((res) => setDocumentCount(res.data))
        .catch((e) => {})
    }
    setCurrentPage(1)
  }
  const loadVacationContracts = () => {
    axios
      .get('contract')
      .then((data) => {
        console.log(data.data)
        setVacationContracts(data.data)
        setOriginalVacationContracts(data.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        } else {
          message.error("Une erreur s'est produite !")
        }
      })
  }

  const cancelDate = () => {
    axios
      .post('contract/cancel-dates', {
        canceller: isClientCancelling ? 'client' : 'user',
        dates: selectedContract.dates,
      })
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e))
  }

  const loadContracts = () => {
    axios
      .get('pld-contract')
      .then((data) => {
        console.log(data.data)
        setContracts(data.data)
        setOriginalContracts(data.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        } else {
          message.error("Une erreur s'est produite !")
        }
      })
  }

  const handleCheckboxClick = (idx) => {
    // Create a new copy of selectedContract.dates
    const newDates = selectedContract.dates.map((date, i) => {
      // If the current index is the clicked one, handle the cancelled field
      if (i === idx) {
        // If the checkbox is currently checked (cancelled is true), remove the cancelled field
        if (date.cancelled) {
          // Create a new object without the cancelled field
          const { cancelled, ...rest } = date
          return rest // Return the object without 'cancelled'
        } else {
          // Otherwise, add the cancelled field if it doesn't exist (checkbox is checked)
          return {
            ...date,
            cancelled: true,
          }
        }
      }
      return date // Other dates remain unchanged
    })

    console.log(newDates)
    // Update the state with the new dates array
    setSelectedContract((prevContract) => ({
      ...prevContract,
      dates: newDates,
    }))
  }

  return (
    <>
      {isCancelDate && (
        <Modal
          width={800}
          open={isCancelDate}
          footer={null}
          onCancel={() => setIsCancelDate(false)}
        >
          <div className="mt-10 flex flex-col items-center">
            <p className="text-xl font-semibold mb-4">
              Qui annule le(s) date(s) ?
            </p>
            <p className="w-60 flex items-center space-x-2">
              <Checkbox
                checked={!isClientCancelling}
                onClick={() => setIsClientCancelling(false)}
              />
              <span>L'interimaire</span>
            </p>
            <p className="w-60 mb-6 flex items-center space-x-2">
              <Checkbox
                checked={isClientCancelling}
                onClick={() => setIsClientCancelling(true)}
              />
              <span>Le client</span>
            </p>

            <p className="text-xl mb-4 font-semibold">Quelle(s) date(s) ?</p>
            {selectedContract.dates.map((date, idx) => {
              return (
                <p className="w-60 flex items-center space-x-2">
                  <Checkbox onClick={() => handleCheckboxClick(idx)} />
                  <span>{formatJsDate(date.dateDebut)}</span>
                </p>
              )
            })}

            <button
              onClick={() => cancelDate()}
              className="p-2 bg-purple-500 text-white rounded-md w-60 mt-10"
            >
              Annuler les dates
            </button>
          </div>
        </Modal>
      )}
      {loadingSearch && (
        <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className="flex justify-end ml-20 mb-10 mt-10">
        <div className="flex items-center space-x-2">
          <Input
            className="w-80  rounded-md border border-gray-200 focus:outline-none"
            placeholder="Recherche"
            style={{ outline: 'none' }}
            onChange={(e) => selectSearchWord(e.target.value)}
          />
          <button
            onClick={() => search()}
            className="bg-green-500 text-white p-2 rounded-md"
          >
            Rechercher
          </button>

          <img src={xlsIcon} className="w-8 h-8 mx-2 hover:cursor-pointer" />
          <FilterListOutlinedIcon className="w-8 h-8 text-gray-500 hover:cursor-pointer" />
        </div>
      </div>

      <div className="w-3/5 mx-auto flex items-center justify-between text-lg font-semibold mt-6 mb-12">
        <p
          className={`${isInterim ? 'underline' : ''}`}
          onClick={() => {
            switchToInterim(true)
          }}
        >
          Interim
        </p>
        <p
          className={`${isVacation ? 'underline' : ''}`}
          onClick={() => {
            switchToInterim(false)
          }}
        >
          Vacation
        </p>
      </div>
      <div className="flex items-center justify-between  mb-6 mx-20 text-xs">
        <p className="w-1/6 text-center  animate-fadeIn">N° DE CONTRAT</p>
        <p className="w-1/6 text-center">ENTREPRISE</p>
        <p className="w-1/6 text-center">INTERIMAIRE</p>
        <p className="w-1/6 text-center">DATE DE CONTRAT</p>
        <p className="w-1/6 text-center">SIGNATURE</p>
        <p className="w-1/6 text-center">ACTION</p>
      </div>

      {loadingContract && (
        <div className="flex items-center justify-center my-3 ">
          <Spin className="w-20" />
        </div>
      )}
      {isInterim && (
        <div className="flex flex-col space-y-4">
          {contracts.map((contract, idx) => {
            return (
              <div
                key={idx}
                onClick={()=> generatePDF(contract)}
                className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between mx-20 bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
              >
                <div className="w-1/6">
                  <p className="font-bold">J'M INTERIM</p>
                  <p className="text-gray-500">Asnieres sur seine</p>
                  <p className="text-gray-500">{contract.IdContrat}</p>
                </div>

                <div className="w-1/6 flex items-center space-x-2">
                  <div>
                    <p>
                      <LocationOnIcon className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    <p className="font-bold">{contract.RaisonSociale}</p>
                    <p className="text-gray-500">
                      {' '}
                      {contract.AdresseRue} - {contract.AdresseCodePostal}
                    </p>
                    <p className="text-gray-500">{contract.AdresseVille}</p>
                  </div>
                </div>

                <div className="w-1/6 flex items-center space-x-2">
                  <AccountBoxIcon className="text-3xl text-gray-300" />
                  <div>
                    <p className="font-bold text-xs">{contract.NomPrenom}</p>
                    <p className="text-xs text-gray-500">
                      {contract.Profession}
                    </p>
                  </div>
                </div>

                <div className="w-1/6 flex items-center space-x-2 justify-center">
                  <div>
                    <p>
                      <CalendarMonth className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    <p className="font-bold text-right">
                      Du {formatDate(contract.DateDebut)}
                    </p>
                    <p className="font-bold text-right">
                      au {formatDate(contract.DateFin)}
                    </p>
                  </div>
                </div>

                <div className="w-1/6 flex flex-col items-center space-y-1 justify-center">
                  {contract.signedByClient && (
                    <p className="text-green-400 font-semibold">
                      Signé par le client
                    </p>
                  )}
                </div>

                <div className="w-1/6 flex items-center space-x-2 justify-center">
                  {/* <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        <p
                          onClick={() => generatePDF(contract)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Visualiser</span>
                        </p>
                        <p
                          onClick={() => generatePDF(contract)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Télécharger</span>
                        </p>
                        <p
                          onClick={() => dematContract(contract._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Dematerialiser</span>
                        </p>
                        <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                          <span>Relancer signature client</span>
                        </p>
                      </div>
                    }
                  >
                    <Button>...</Button>
                  </Popover> */}
                </div>

                {/*   <div className="w-1/5 flex justify-center items-center">
                <p
                  className={`hidden  text-gray-500 p-1 rounded-md text-sm w-fit group-hover:flex items-center space-x-2 group-hover:animate-fadeIn`}
                >
                  <SearchIcon  />
                  <SimCardDownloadIcon onClick={() => downloadPdf(contract)} />
                </p>
              </div> */}
              </div>
            )
          })}
        </div>
      )}

      {isVacation && (
        <div className="flex flex-col space-y-4">
          {vacationContracts.map((contract, idx) => {
            return (
              <div
                key={idx}
                className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between mx-20 bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
              >
                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/6"
                >
                  <p className="font-bold">J'M INTERIM</p>
                  <p className="text-gray-500">Asnieres sur seine</p>
                  <p className="text-gray-500">{contract.contractNumber}</p>
                </div>

                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/6 flex items-center space-x-2"
                >
                  <div>
                    <p>
                      <LocationOnIcon className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    <p className="font-bold">{contract.client.companyName}</p>
                    <p className="text-gray-500">
                      {' '}
                      {contract.client.address} - {contract.client.postCode}
                    </p>
                    <p className="text-gray-500">{contract.client.town}</p>
                  </div>
                </div>

                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/6 flex items-center space-x-2"
                >
                  <AccountBoxIcon className="text-3xl text-gray-300" />
                  <div>
                    <p className="font-bold text-xs">
                      {contract.candidate.firstname}{' '}
                      {contract.candidate.lastname}
                    </p>
                    <p className="text-xs text-gray-500">
                      {contract.qualification}
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/6 flex items-center space-x-2 justify-center"
                >
                  <div>
                    <p>
                      <CalendarMonth className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    {contract.dates.map((date, idx) => {
                      return (
                        <p key={idx} className="font-bold text-right">
                          {formatJsDate(date.dateDebut)}
                        </p>
                      )
                    })}
                  </div>
                </div>
                <div className="w-1/6"></div>

                <div className="w-1/6 flex items-center justify-center">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        {/* <p onClick={()=> {
                          setIsCancelDate(true);
                          setSelectedContract(contract);
                          console.log(contract);
                          }} className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                          <span>Annuler dates</span>
                        </p> */}

                        <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                          <Popconfirm
                            title="Supprimer le contract"
                            description="Voulez vous annuler ce contrat ?"
                            onConfirm={(e) => deleteContract(e, contract._id)}
                            okText="Oui"
                            cancelText="Non"
                            okButtonProps={{
                              className: 'bg-blue-600 text-white',
                            }}
                          >
                            <span>Supprimer contrat</span>
                          </Popconfirm>
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button>...</Button>
                  </Popover>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {!isSearch && (
        <div className="flex  mx-16 justify-end">
          <Pagination
            className="mt-6"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={48}
          />
        </div>
      )}
    </>
  )
}
