import { useRef, useState } from 'react'
import axios from 'axios'
import Alert from '@mui/material/Alert'
import { Modal, Select, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import AxiosInstance from '../../axios/axiosInstance'

export default function CreateClientModal({
  isOpen,
  setIsOpen,
  clients,
  setClients,
}) {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const navigate = useNavigate()

  //company
  const [siret, setSiret] = useState('')
  const [denomination, setDenomination] = useState('')
  const [ape, setApe] = useState('')
  const [address, setAddress] = useState('')
  const [town, setTown] = useState('')
  const [postCode, setPostCode] = useState('')
  const [clientAcquisition, setClientAcquisition] = useState('')
  const [tva, setTva] = useState('')
  const [tvaRate, setTvaRate] = useState('')
  const [commercial, setCommercial] = useState('')
  const [paymentCondition, setPaymentCondition] = useState('')
  const [pld, setPld] = useState('')

  //ADRESSES

  const [addressLivraison, setAddressLivraison] = useState('')
  const [postCodeLivraison, setPostcodeLivraison] = useState('')
  const [townLivraison, setTownLivraison] = useState('')

  const [addressFacturation, setAddressFacturation] = useState('')
  const [postcodeFacturation, setPostcodeFacturation] = useState('')
  const [townFacturation, setTownFacturation] = useState('')

  //contact
  const [contactFirstname, setContactFirstname] = useState('')
  const [contactLastname, setContactLastname] = useState('')
  const [contactEMail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')

  //facturation

  const [facturation, setFacturation] = useState('')

  const cancelButtonRef = useRef(null)

  const [isContact, setIsContact] = useState(false)
  const [isCompany, setIsCompany] = useState(true)
  const [isAddress, setIsAddress] = useState(false)
  const [isFacturation, setIsFacturation] = useState(false)

  const clientAcquisitions = [
    'Téléprospection',
    'Visite clientèle',
    'Réseaux sociaux',
    'Prise de reférence',
    'Contrat cadre',
    'Autre',
  ]

  const paymentConditions = [
    'À Réception',
    'À 10 jours',
    'À 15 jours',
    'Fin de mois',
    '30 jours date de facture',
    '30 jours fdm',
    '30 jours fdm le 5',
    '30 jours fdm le 10',
    '30 jours fdm le 15',
    '30 jours fdm le 20',
    '30 jours fdm le 25',
    'À 45 jours',
    'À 45 jours fdm',
    '60 jours date de facture',
    '60 jours fdm',
    '60 jours fdm le 5',
    '60 jours fdm le 10',
    '60 jours fdm le 15',
    '60 jours fdm le 20',
    '60 jours fdm le 25',
  ]

  const TVA = [
    'TVA 20%',
    'TVA 10%',
    'TVA 8.5%',
    'TVA 5.5%',
    'TVA 2.1%',
    'TVA 0% UE',
    'TVA 0% HORS UE',
  ]

  const tvaArray = TVA.map((tva) => {
    return {
      value: tva,
      label: tva,
    }
  })

  const paymentConditionArray = paymentConditions.map((paymentCondition) => {
    return {
      value: paymentCondition,
      label: paymentCondition,
    }
  })

  const clientAcquisitionArray = clientAcquisitions.map((clientAcquisition) => {
    return {
      value: clientAcquisition,
      label: clientAcquisition,
    }
  })

  const searchSiret = (siret) => {
    setSiret(siret)
    if (siret.length === 14) {
      axios
        .get(`https://data.siren-api.fr/v3/etablissements/${siret}`, {
          headers: {
            //"X-Client-Secret":"IK4mmW87u8wiOqb79n6yRqcX93dTQF2x"
          },
        })
        .then((res) => {
          console.log(res)
          setSiret(siret)
          setDenomination(res.data.etablissement.unite_legale.denomination)
          setApe(res.data.etablissement.unite_legale.activite_principale)
          setAddress(
            res.data.etablissement.numero_voie +
              ' ' +
              res.data.etablissement.type_voie +
              ' ' +
              res.data.etablissement.libelle_voie,
          )
          setTown(res.data.etablissement.libelle_commune)
          setPostCode(res.data.etablissement.code_postal)
        })
        .catch((e) => console.log(e))

      return
    }
  }

  const setSection = (section) => {
    if (section === 'address') {
      if (
        !siret.length ||
        !denomination.length ||
        !ape.length ||
        !tva.length ||
        !address.length ||
        !postCode.length ||
        !town.length ||
        !clientAcquisition.length ||
        !commercial.length
      ) {
        message.error('remplissez tous les champs')
        return
      }

      setIsAddress(true)
      setIsCompany(false)
      setIsContact(false)
      setIsFacturation(false)
    }

    if (section === 'company') {
      setIsAddress(false)
      setIsCompany(true)
      setIsContact(false)
      setIsFacturation(false)
    }

    if (section === 'contact') {
      if (
        !postCodeLivraison.length ||
        !postcodeFacturation.length ||
        !townLivraison.length ||
        !townFacturation.length ||
        !addressLivraison.length ||
        !addressFacturation.length
      ) {
        message.error('remplissez tous les champs')
        return
      }

      setIsAddress(false)
      setIsCompany(false)
      setIsContact(true)
      setIsFacturation(false)
    }

    if (section === 'facturation') {
      if (
        !contactEMail.length ||
        !contactFirstname.length ||
        !contactLastname.length ||
        !contactPhone.length
      ) {
        message.error('remplissez tous les champs')
        return
      }

      setIsAddress(false)
      setIsCompany(false)
      setIsContact(false)
      setIsFacturation(true)
    }
  }

  const resetFields = () => {
    setIsAddress(false)
    setIsCompany(true)
    setIsContact(false)
    setIsFacturation(false)
    setIsOpen(false)
  }

  const selectClientAcquisition = (e) => {
    setClientAcquisition(e)
  }

  const selectFacturation = (e) => {
    setFacturation(e)
  }

  const addClient = () => {
    if (!facturation.length) {
      message.error('remplissez tous les champs')
      return
    }

    AxiosInstance.post('client', {
      siret,
      companyName: denomination,
      address: address,
      town: town,
      postCode: postCode,
      pld: pld,
      APE: ape,
      TVA: tva,
      clientAcquisition,
      commercial,
      addressLivraison,
      addressFacturation,
      postCodeLivraison,
      postCodeFacturation: postcodeFacturation,
      townLivraison,
      townFacturation,
      contacts: [
        {
          firstname: contactFirstname,
          lastname: contactLastname,
          email: contactEMail,
          mobile: contactPhone.split(' ').join(''),
        },
      ],
      contactFirstname,
      contactLastname,
      contactEMail,
      contactPhone,
      facturation,
      tvaRate,
    })
      .then((res) => {
        message.success('Client créé')
        console.log(res)
        const newClients = [...clients]
        newClients.push(res.data)
        setSection('company')
        setClients(newClients)
        setIsOpen(false)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        } else if (e.response.data.statusCode === 409) {
          message.error('Le client existe deja!')
        }
      })
  }

  const copySiegeAddressLivraison = () => {
    setAddressLivraison(address)
    setTownLivraison(town)
    setPostcodeLivraison(postCode)
  }

  const copySiegeAddressFacturation = () => {
    setAddressFacturation(address)
    setTownFacturation(town)
    setPostcodeFacturation(postCode)
  }

  const copyLivraisonFacturation = () => {
    setAddressFacturation(addressLivraison)
    setTownFacturation(townLivraison)
    setPostcodeFacturation(postCodeLivraison)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={resetFields} width={1000}>
      <div className="py-16 h-full bg-white">
        <div className="grow w-full flex flex-col justify-center h-full sm:flex sm:items-start gap-8 ">
          <p className="text-3xl font-light uppercase mx-auto">Créer Client</p>

          <p className="flex text-xl items-center justify-around w-full text-center space-x-2">
            <span className={isCompany ? 'font-bold underline' : ''}>
              Societe
            </span>
            <span className={isAddress ? 'font-bold underline' : ''}>
              Adresse
            </span>
            <span className={isContact ? 'font-bold underline' : ''}>
              Contact
            </span>
            <span>Facturation</span>
          </p>
          <div className="flex flex-col justify-start text-center w-full nav:w-3/5 h-full mx-auto">
            {isCompany && (
              <div>
                <div className="flex items-center space-x-2 mb-6">
                  <input
                    name="siren"
                    type="text"
                    placeholder="SIRET"
                    onChange={(e) => searchSiret(e.target.value)}
                    value={siret}
                    className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                  />
                </div>

                <input
                  name="companyName"
                  type="text"
                  placeholder="Raison Sociale"
                  value={denomination}
                  onChange={(e) => setDenomination(e.target.value)}
                  className="grow mb-6 max-h-12 w-full text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />

                <div className="flex items-center space-x-2 mb-6">
                  <input
                    name="pld"
                    type="text"
                    placeholder="Numero PLD"
                    onChange={(e) => setPld(e.target.value)}
                    value={pld}
                    className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                  />
                </div>

                <input
                  name="TVA"
                  type="text"
                  placeholder="TVA intra"
                  value={tva}
                  onChange={(e) => setTva(e.target.value)}
                  className="grow mb-6 max-h-12 w-full text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />
                <div className="flex items-center space-x-2 mb-4">
                  <input
                    name="APE"
                    type="text"
                    placeholder="APE"
                    value={ape}
                    onChange={(e) => setApe(e.target.value)}
                    className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                  />
                </div>

                <input
                  name="address"
                  type="text"
                  placeholder="Adresse"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="grow mb-6 w-full max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />
                <div className="flex items-center space-x-2 ">
                  <input
                    name="postCode"
                    type="text"
                    placeholder="Code Postal"
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                  />
                  <input
                    name="town"
                    type="text"
                    placeholder="Ville"
                    value={town}
                    onChange={(e) => setTown(e.target.value)}
                    className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                  />
                </div>

                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => selectClientAcquisition(e)}
                  optionFilterProp="children"
                  value={clientAcquisition}
                  placeholder="Source d'acquisition"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={clientAcquisitionArray}
                />

                <input
                  name="commercial"
                  type="text"
                  placeholder="Commercial"
                  value={commercial}
                  onChange={(e) => setCommercial(e.target.value)}
                  className="grow mb-4 max-h-12 w-full text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />

                <div className="flex mb-2 items-center justify-center w-full">
                  <button
                    onClick={() => setSection('address')}
                    className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 
          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    SUIVANT
                  </button>
                </div>
              </div>
            )}

            {showErrorAlert && (
              <Alert severity="error" onClose={() => setShowErrorAlert(false)}>
                Veuillez remplir tous les champs!
              </Alert>
            )}

            {isAddress && (
              <div>
                <div className="flex items-start justify-between mb-6 mt-12">
                  <div className="flex flex-col  items-start space-y-1">
                    <p>Adresse de livraison</p>
                    <p
                      onClick={() => copySiegeAddressLivraison()}
                      className="text-xs text-blue-500 text-center"
                    >
                      Recopier adresse de la societé
                    </p>
                  </div>
                  <div className="flex items-start flex-col space-y-2">
                    <p>Adresse de facturation</p>
                    <p
                      onClick={() => copySiegeAddressFacturation()}
                      className="text-xs text-blue-500 text-center"
                    >
                      Recopie adresse du siege
                    </p>

                    <p
                      onClick={() => copyLivraisonFacturation()}
                      className="text-xs text-blue-500 text-center"
                    >
                      Recopie adresse de livraison
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-center justify-between space-y-2">
                    <input
                      name="adresse_livraison"
                      type="text"
                      placeholder="Adresse de livraison"
                      onChange={(e) => setAddressLivraison(e.target.value)}
                      value={addressLivraison}
                      className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                    />

                    <input
                      name="ville_livraison"
                      type="text"
                      placeholder="Ville de livraison"
                      onChange={(e) => setTownLivraison(e.target.value)}
                      value={townLivraison}
                      className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                    />

                    <input
                      name="codePostal_livraison"
                      type="text"
                      placeholder="code postal de livraison"
                      onChange={(e) => setPostcodeLivraison(e.target.value)}
                      value={postCodeLivraison}
                      className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                    />
                  </div>

                  <div className="flex flex-col items-center justify-between space-y-2">
                    <input
                      name="adresse_facturation"
                      type="text"
                      placeholder="Adresse de facturation"
                      onChange={(e) => setAddressFacturation(e.target.value)}
                      value={addressFacturation}
                      className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                    />

                    <input
                      name="ville_facturation"
                      type="text"
                      placeholder="Ville de facturation"
                      onChange={(e) => setTownFacturation(e.target.value)}
                      value={townFacturation}
                      className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                    />

                    <input
                      name="codePostal_facturation"
                      type="text"
                      placeholder="code postal de facturation"
                      onChange={(e) => setPostcodeFacturation(e.target.value)}
                      value={postcodeFacturation}
                      className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                    />
                  </div>
                </div>

                <div className="flex mb-2 items-center space-x-6 justify-center w-full">
                  <button
                    onClick={() => setSection('company')}
                    className="rounded-full bg-black  py-4 px-10 
                          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    RETOUR
                  </button>

                  <button
                    onClick={() => setSection('contact')}
                    className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 
                          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    SUIVANT
                  </button>
                </div>
              </div>
            )}

            {isContact && (
              <div className="mt-12">
                <input
                  name="firstname"
                  type="text"
                  placeholder="Nom"
                  onChange={(e) => setContactLastname(e.target.value)}
                  value={contactLastname}
                  className=" mb-4   w-full text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 border-mountain-00 placeholder-gray-400"
                />
                <input
                  name="lastname"
                  type="text"
                  placeholder="Prenom"
                  onChange={(e) => setContactFirstname(e.target.value)}
                  value={contactFirstname}
                  className=" mb-4  w-full text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setContactEmail(e.target.value)}
                  value={contactEMail}
                  className=" mb-4 w-full  text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />
                <input
                  name="phone"
                  type="phone"
                  placeholder="Téléphone"
                  onChange={(e) => setContactPhone(e.target.value)}
                  value={contactPhone}
                  className="mb-4 w-full  text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                />

                <div className="flex mb-2 items-center space-x-6 justify-center w-full">
                  <button
                    onClick={() => setSection('address')}
                    className="rounded-full bg-black  py-4 px-10 
                          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    RETOUR
                  </button>

                  <button
                    onClick={() => setSection('facturation')}
                    className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 
                          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    SUIVANT
                  </button>
                </div>
              </div>
            )}

            {isFacturation && (
              <div>
                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => selectFacturation(e)}
                  optionFilterProp="children"
                  placeholder="Conditions de paiement"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={paymentConditionArray}
                />

                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => setTvaRate(e)}
                  optionFilterProp="children"
                  placeholder="TVA"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={tvaArray}
                />
                <div className="flex mb-2 items-center space-x-6 justify-center w-full">
                  <button
                    onClick={() => setSection('contact')}
                    className="rounded-full bg-black  py-4 px-10 
                          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    RETOUR
                  </button>

                  <button
                    onClick={() => addClient()}
                    className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 
                          mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                  >
                    AJOUTER LE CLIENT
                  </button>
                </div>
              </div>
            )}

            {showSuccessAlert && (
              <Alert
                severity="success"
                onClose={() => setShowSuccessAlert(false)}
              >
                Client ajouté!
              </Alert>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
