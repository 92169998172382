import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
import qualifications from '../../qualifications/qualifications'
import { codePostaux } from '../../codePostaux/code'
import { habilitationsList } from './data/habilitationsList'
import { cacesList } from './data/cacesList'
import axios from 'axios'
import { Button, Drawer, Modal, Select, TreeSelect } from 'antd'
import { Input } from 'antd'
import { DatePicker, Space } from 'antd'
import locale from 'antd/es/date-picker/locale/fr_FR'
import AxiosInstance from '../../axios/axiosInstance'

const { RangePicker } = DatePicker

const FilterCandidat = ({
  open,
  setIsOpen,
  setCandidates,
  originalCandidates,
}) => {
  const [qualificationFilter, setQualificationFilter] = useState([])
  const [codePostalFilter, setCodePostalFilter] = useState([])
  const [habilitationFilter, setHabilitationFilter] = useState([])
  const [licenceFilter, setLicenceFilter] = useState([])
  const [diplomasFilter, setDiplomasFilter] = useState([])
  const [cacesFilter, setCacesFilter] = useState([])

  const licencesArray = [
    'AM',
    'A1',
    'A2',
    'A',
    'B1',
    'B',
    'C1',
    'C',
    'D1',
    'D',
    'BE',
    'C1E',
    'CE',
    'D1E',
    'DE',
  ]

  const diplomasArray = [
    'CAP - BEP',
    'Baccalauréat',
    'DEUG - BTS - DUT - DEUST',
    'Licence - BUT',
    'Maîtrise - Master',
    'Doctorat',
  ]

  const onChangeQualification = (newValue) => {
    console.log(newValue)
    setQualificationFilter(newValue)
  }

  const onChangeCodePostal = (newValue) => {
    setCodePostalFilter(newValue)
  }

  const onChangeLicence = (newValue) => {
    setLicenceFilter(newValue)
  }

  const onChangeDiplomas = (newValue) => {
    setDiplomasFilter(newValue)
  }
  const onChangeCaces = (newValue) => {
    setCacesFilter(newValue)
  }

  const onChangeHabilitation = (newValue) => {
    setHabilitationFilter(newValue)
  }

  const licenceData = licencesArray.map((licence) => {
    return {
      title: licence,
      value: licence,
      key: licence,
    }
  })

  const cacesData = cacesList.map((caces) => {
    return {
      title: caces,
      value: caces,
      key: caces,
    }
  })

  const diplomasData = diplomasArray.map((diploma) => {
    return {
      title: diploma,
      value: diploma,
      key: diploma,
    }
  })

  const qualificationData = qualifications.map((qualification) => {
    return {
      title: qualification,
      value: qualification,
      key: qualification,
    }
  })

  const habilitationsData = habilitationsList.map((habilitation) => {
    return {
      title: habilitation,
      value: habilitation,
      key: habilitation,
    }
  })

  const codePostalData = codePostaux.map((code) => {
    return {
      title: code,
      value: code.split(' : ')[0],
    }
  })

  const codePostalProps = {
    treeData: codePostalData,
    onChange: onChangeCodePostal,
    value: codePostalFilter,
    treeCheckable: true,
    placeholder: 'Secteur géographique',
  }

  const qualifProps = {
    treeData: qualificationData,
    onChange: onChangeQualification,
    value: qualificationFilter,
    treeCheckable: true,
    placeholder: 'Qualification',
  }

  const habilitationProps = {
    treeData: habilitationsData,
    onChange: onChangeHabilitation,
    value: habilitationFilter,
    treeCheckable: true,
    placeholder: 'Habilitation',
  }

  const permisProps = {
    treeData: licenceData,
    onChange: onChangeLicence,
    value: licenceFilter,
    treeCheckable: true,
    placeholder: 'Permis',
  }

  const cacesProps = {
    treeData: cacesData,
    onChange: onChangeCaces,
    value: cacesFilter,
    treeCheckable: true,
    placeholder: 'CACES',
  }

  /*  const locomotionProps = {
    treeData: qualificationData,
    onChange: onChangeQualification,
    value: qualificationFilter,
    treeCheckable: true,
    placeholder: 'Moyen de transport',
  } */

  const diplomaProps = {
    treeData: diplomasData,
    onChange: onChangeDiplomas,
    value: diplomasFilter,
    treeCheckable: true,
    placeholder: 'Diplomes',
  }

  const getFilteredCandidates = () => {
    AxiosInstance.get('tempworker-candidate/filter', {
      params: {
        qualifications: qualificationFilter.join(','),
        codePostal: codePostalFilter.join(','),
        habilitations: habilitationFilter.join(','),
        caces: cacesFilter.join(','),
        diplomas: diplomasFilter.join(','),
        licences: licenceFilter.join(','),
      },
    })
      .then((res) => {
        setCandidates(res.data)
        console.log(res.data)
        setIsOpen(false)
      })
      .catch((e) => console.log(e))
  }

  const resetCandidates = () => {
    setQualificationFilter([])
    setCacesFilter([])
    setLicenceFilter([])
    setHabilitationFilter([])
    setCodePostalFilter([])
    setDiplomasFilter([])
    setCandidates(originalCandidates)
  }

  return (
    <Drawer
      title="Filtrer Candidat"
      open={open}
      onClose={() => setIsOpen(false)}
    >
      <div className=" flex h-full flex-col">
        <div className="grow flex flex-col justify-between">
          <div className="flex items-center flex-col hover:cursor-pointer px-10">
            <div className="w-full flex flex-col items-start space-y-2">
              <DatePicker
                className="w-full rounded-md"
                placeholder="Date de creation"
                onChange={(date, dateString) => {}}
                format={'DD/MM/YYYY'}
              />

              <Select
                className="w-full rounded-full"
                optionFilterProp="children"
                onChange={(e) => {}}
                placeholder="Recruté(e) par"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: 'En cours de traitement',
                    label: 'En cours de traitement',
                    key: '0',
                  },
                  {
                    value: 'En attente des RH',
                    label: 'En attente des RH',
                    key: '1',
                  },
                  {
                    value: 'En attente du RIB',
                    label: 'En attente du RIB',
                    key: '2',
                  },

                  {
                    value: 'Validée',
                    label: 'Validée',
                    key: '3',
                  },
                  {
                    value: 'Refusée',
                    label: 'Refusée',
                    key: '4',
                  },
                ]}
              />
              <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...qualifProps}
              />
              <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...codePostalProps}
              />
              <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...habilitationProps}
              />
              <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...permisProps}
              />
              <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...cacesProps}
              />
              {/*   <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...locomotionProps}
              /> */}
              <TreeSelect
                className="w-full rounded-full"
                size="large"
                {...diplomaProps}
              />
            </div>
            <div>
              <p className="flex items-center space-x-2 justify-center mt-6">
                <button
                  className="text-white rounded-md p-2 m-2 text-xs  bg-green-500"
                  onClick={getFilteredCandidates}
                >
                  VALIDER
                </button>
                <button
                  className="text-white rounded-md p-2 m-2 text-xs bg-black"
                  onClick={resetCandidates}
                >
                  REINITIALISER
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default FilterCandidat
