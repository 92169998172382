import myImage from '../../assets/img/logopdf.png'
import axios from '../../axios/axiosInstance'
import recours from '../../recours/recours'
import cgvVacation from '../../assets/img/cgv-vacation.png'
import ct92 from '../../assets/CT92.pdf'
import { message } from 'antd'

export const createPdfCandidate = async (contract, pdf) => {
  const [userData, clientData] = await Promise.all([
    axios.get(
      `tempworker-candidate/${contract.IdInterimaire}/tempoInterimaire`,
    ),
    axios.get(`tempworker-candidate/${contract.IdClient}/tempoClient`),
  ])
    .then(([userRes, clientRes]) => [userRes.data[0], clientRes.data[0]])
    .catch((e) => {
      message.error("Une erreur s'est produite !")
    })

  const img = new Image()
  img.src = myImage

  pdf.addImage(img, 'PNG', 10, 10, 35, 35)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(14)
  console.log(contract.agency)

  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text("J'm intérim SAS", 45, 15)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text("J'm Capeneo", 45, 15)
  else 
    pdf.text("J'm Capeneo Santé", 45, 15)


  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(9)

  pdf.text('Capital de 80 000', 45, 22)


  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('4 avenue Laurent Cély - 92600 Asnières sur Seine ', 45, 26)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('26 Bis Avenue Ribot - 19100 Brive La Gaillarde', 45, 26)
  else
    pdf.text('64 Avenue Benjamin Baillaud - 31500 Toulouse', 45, 26)


  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('T : 01.80.88.21.35 - @ : recrutement@jm-interim.com', 45, 30)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('T : 05.55.17.49.79 - @ : brive@jm-interim.com', 45, 30)
  else
    pdf.text('T : 05.61.11.11.00 - @ : sante@jm-interim.com', 45, 30)  
  
  
  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('SIRET : 919 125 518 00010 APE : 7820z', 45, 34)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('SIRET : 919 125 518 00028 APE : 7820z', 45, 34)
  else
  pdf.text('SIRET : 919 125 518 00036 APE : 7820z', 45, 34)

  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('URSSAF : 117000001571042223 Montreuil (93)', 45, 38)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('URSSAF : 747000000931755615 Limousin (19)', 45, 38)
  else
    pdf.text('URSSAF : 737000000185770617 Toulouse (31)', 45, 38)

  pdf.text('Convention collective (IDCC 2378 ) ', 45, 42)
  pdf.text(
    'Personnel intérimaire des entreprises de travail temporaire',
    45,
    46,
  )
  pdf.text('Garant financier (art. L.1251-49 du CT) : Atradius', 45, 50)
  pdf.text('159 Rue Anatole France, 92300 Levallois-Perret', 45, 54)
  pdf.text(
    'Intérimaires Prévoyance, TSA 60008, 92 599 Levallois-Perret Cedex',
    45,
    58,
  )

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(14)

  pdf.text('Contrat', 160, 15)

  pdf.setFontSize(12)

  pdf.setFont('Helvetica', 'Bold')
  pdf.text(contract.NumeroContrat.toString(), 180, 15)

  pdf.setFont('Helvetica', 'normal')
  pdf.setFontSize(12)

  if (contract.NumeroAvenant) {
    pdf.setFontSize(10)
    pdf.text('Avenant', 160, 21)
    pdf.setFont('Helvetica', 'bold')
    pdf.text(contract.NumeroAvenant.toString(), 184, 21)
    pdf.setFont('Helvetica', 'normal')
  } else {
    pdf.text('Initial', 160, 21)
  }

  pdf.setFont('Helvetica', 'Bold')
  pdf.setFontSize(14)

  pdf.setTextColor(254, 0, 118)
  pdf.text('Contrat de mission temporaire', 130, 35)
  pdf.setTextColor(0, 0, 0)
  pdf.setDrawColor(254, 0, 118)
  pdf.line(130, 36, 202, 36)

  pdf.setDrawColor(0, 0, 0)

  pdf.roundedRect(10, 65, 6, 35, 1, 1)
  pdf.setFontSize(12)
  pdf.text('Client', 15, 88, null, 90)

  pdf.roundedRect(18, 65, 85, 35, 1, 1)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text(contract.RaisonSociale, 20, 72)

  pdf.setFont('Helvetica', 'normal')

  pdf.text(clientData.AdresseRue, 20, 80)
  pdf.text(clientData.AdresseCodePostal + ' ' + clientData.AdresseVille, 20, 88)

  pdf.roundedRect(107, 65, 6, 35, 1, 1)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Interimaire', 112, 92, null, 90)
  pdf.setFont('Helvetica', 'normal')

  pdf.roundedRect(115, 65, 85, 35, 1, 1)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text(`${userData.Nom} ${userData.Prenom}`, 117, 72)

  pdf.setFont('Helvetica', 'normal')

  pdf.text(userData.AdresseRue, 117, 80)
  pdf.text(userData.AdresseCodePostal + ' ' + userData.AdresseVille, 117, 88)

  pdf.setFontSize(8)
  pdf.rect(10, 102, 93, 40)

  pdf.text('Nº matricule :', 109, 107)
  pdf.text(contract.IdInterimaire.toString(), 137, 107)
  pdf.text('Nationalité :', 109, 112)
  pdf.text(toCamelCase(userData.TxtNationalite.toString()), 137, 112)
  pdf.text('Né(e) le :', 109, 117)
  pdf.text(
    formatDate(userData.DateNaissance) +
      ' à ' +
      userData.LieuNaissance +
      ' (' +
      userData.TxtPaysNaissance +
      ')',
    137,
    117,
  )
  pdf.text('Qualification :', 109, 122)
  const contractProfession =
    contract.PCS[0] === '1' ||
    contract.PCS[0] === '2' ||
    contract.PCS[0] === '3'
      ? contract.Profession + '(Cadre) PCS : ' + contract.PCS
      : contract.Profession + '(Non Cadre) PCS : ' + contract.PCS
  pdf.text(contractProfession.substring(0, 40), 137, 122)
  if (contractProfession.length > 40)
    pdf.text(contractProfession.substring(40), 137, 127)
  pdf.text('Identité :', 109, 132)
  const ids = [1, 3, 4, 5]
  console.log(userData.TabCartes)
  const tabCartes = userData.TabCartes.filter((obj) =>
    ids.includes(obj.IdCarte),
  )
  const idFile = tabCartes[0]
  pdf.text(
    idFile.TxtCarte +
      ' : ' +
      idFile.Reference1 +
      '  Fin :' +
      formatDate(idFile.DateFin),
    137,
    132,
  )

  pdf.rect(107, 102, 93, 40)
  pdf.text('Nº Client :', 12, 105)
  pdf.text(contract.IdClient.toString(), 40, 105)

  pdf.text('Contact :', 12, 109)
  pdf.text(contract.Interlocuteur, 40, 109)

  pdf.text('Lieu mission :', 12, 113)
  pdf.text(contract.AdresseRue, 40, 113)
  if (contract.AdresseSuite.length) {
    pdf.text(contract.AdresseSuite, 40, 117)
    pdf.text(contract.AdresseCodePostal + ' ' + contract.AdresseVille, 40, 121)
  } else {
    pdf.text(contract.AdresseCodePostal + ' ' + contract.AdresseVille, 40, 117)
  }

  pdf.text("Moyen d'accès :", 12, 125)
  pdf.text('Téléphone :', 12, 129)
  pdf.text(clientData.Telephone1, 40, 129)

  pdf.text('Siret :', 12, 133)
  pdf.text(clientData.SIRET, 40, 133)

  pdf.text('NAF :', 70, 133)
  pdf.text(clientData.NAF, 80, 133)
  pdf.text('Effectif :', 12, 137)
  pdf.text(clientData.TxtEffectif, 40, 137)

  pdf.text('Durée Col :', 70, 137)

  pdf.rect(10, 144, 93, 20)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Motif :', 12, 147)
  pdf.setFont('Helvetica', 'normal')

  let [firstSencenceRecours, ...recoursArray] = splitStringWithoutCuttingWords(
    recours[contract.IdRecours].replaceAll('\r\n', ''),
    60,
  )

  console.log(recours[contract.IdRecours])
  pdf.text(firstSencenceRecours, 24, 147)

  recoursArray = splitStringWithoutCuttingWords(recoursArray.join(' '), 70)
  for (let i = 0; i < recoursArray.length; i++) {
    pdf.text(recoursArray[i], 11, 150 + i * 3)
  }

  pdf.rect(107, 144, 93, 20)
  pdf.text('Justificatif :', 109, 147)

  let [firstSencenceJustif, ...justifArray] = splitStringWithoutCuttingWords(
    contract.TxtJustificatif.replaceAll('\r\n', ' ').replaceAll('\r', ' '),
    50,
  )
  pdf.text(firstSencenceJustif, 123, 147)

  justifArray = splitStringWithoutCuttingWords(justifArray.join(' '), 70)
  for (let i = 0; i < justifArray.length; i++) {
    pdf.text(justifArray[i], 110, 150 + i * 3)
  }

  pdf.rect(10, 166, 93, 50)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Dates', 11, 169)
  pdf.text(formatDate(contract.DateDebut), 25, 169)
  pdf.text(formatDate(contract.DateFin), 45, 169)
  pdf.text('Souplesse', 11, 173)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(formatDate(contract.DateDebutSouplesse), 30, 173)
  pdf.text(formatDate(contract.DateFinSouplesse), 50, 173)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Durée hebdo', 11, 177)
  pdf.text("Période d'essai", 50, 177)
  pdf.text('Horaires', 11, 181)
  pdf.text('Retraite', 11, 185)

  pdf.setFont('Helvetica', 'normal')

  const textRetraite =
    "*Le terme de la mission prévu dans le contrat initial ou dans l'avenant de prolongation peut être aménagé dans les conditions prévues aux articles L.1251-30 et L.1251-31."
  const textRetraiteArray = splitStringWithoutCuttingWords(textRetraite, 70)

  for (let i = 0; i < textRetraiteArray.length; i++) {
    pdf.text(textRetraiteArray[i], 11, 200 + i * 3)
  }

  pdf.text('REUNI 33 quai du Pdt Paul Doumer 92672 COURBEVOIE', 25, 185)
  pdf.text('cedex.', 11, 189)
  pdf.setFont('Helvetica', 'bold')

  pdf.text('Equip de securité : ', 11, 193)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(parseFloat(contract.NbHeureHebdo).toFixed(2) + '  Hrs', 30, 177)
  pdf.text(
    contract.HoraireAMDebut.substring(0, 2) +
      'H' +
      contract.HoraireAMDebut.substring(2) +
      '-' +
      contract.HoraireAMFin.substring(0, 2) +
      'H' +
      contract.HoraireAMFin.substring(2),
    30,
    181,
  )
  pdf.text(contract.NbJoursEssai + '  JT', 72, 177)

  let euEpi =
    'EU : ' +
    contract.TabEPIs.filter((epi) => epi.FourniEU === 'O')
      .map((epi) => epi.TxtEPI)
      .join(', ')
  let [firstSencenceEpi, ...epiArray] = splitStringWithoutCuttingWords(
    euEpi,
    50,
  )
  pdf.text(firstSencenceEpi, 37, 193)
  epiArray = splitStringWithoutCuttingWords(epiArray.join(' '), 70)
  let epiHeight = 196
  for (let x of epiArray) {
    pdf.text(x, 11, epiHeight)
    epiHeight += 3
  }
  //pdf.text("ETT : "+contract.TabEPIs.filter(epi=>epi.FourniETT==="O").map(epi=>epi.TxtEPI).join(", "),,193)
  pdf.text('au', 40, 169)
  pdf.text('inclus*', 65, 169)
  pdf.text('au', 45, 173)

  pdf.rect(107, 166, 93, 50)

  pdf.setFont('Helvetica', 'Bold')
  pdf.text('Caractéristiques du poste', 108, 169)

  pdf.setFont('Helvetica', 'normal')

  console.log(contract.TxtCaracteristiquePoste)

  let [firstSentence, ...caracteristiqueArray] = splitStringWithoutCuttingWords(
    contract.TxtCaracteristiquePoste.replaceAll('\r\n', '').replaceAll("\n",""),
    40,
  )

  if (firstSentence) {
    pdf.text(firstSentence, 146, 169)
  }
  let height = 172
  caracteristiqueArray = splitStringWithoutCuttingWords(
    caracteristiqueArray.join(' '),
    70,
  )
  for (let x of caracteristiqueArray) {
    pdf.text(x, 108, height)
    height += 3
  }

  pdf.setFont('Helvetica', 'Bold')
  pdf.text('Risques professionnels', 108, height)
  pdf.setFont('Helvetica', 'normal')

  pdf.text("Ce poste n'est pas à risque selon articles", 142, height)
  height += 3
  pdf.text('du code du travail en vigueur (dont L.4154-2)', 108, height)
  height += 3
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Facteurs pénibilité : ', 108, height)
  pdf.setFont('Helvetica', 'normal')

  let penib = ''
  contract.PenibiliteNC === 'O' ? (penib = 'Non communiqué') : (penib = '')
  pdf.setFont('Helvetica', 'normal')
  pdf.text(' (art. L.4161-1 et R.4163-7 du CT) ' + penib, 133, height)

  pdf.setFont('Helvetica', 'Bold')
  height += 3

  pdf.text('Reférence de commande', 108, height)
  height += 3
  pdf.text('Salaire de référence ', 108, height)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(contract.TauxHoraireRef + 'EUR', 140, height)
  height += 3

  const consigneArray = splitStringWithoutCuttingWords(
    contract.TxtConsignePoste.replaceAll('\r\n', ''),
    70,
  )
  console.log(consigneArray)
  for (let x of consigneArray) {
    pdf.text(x, 108, height)
    height += 3
  }

  pdf.setFillColor(244, 96, 165)

  pdf.rect(10.1, 218.1, 92.8, 8, 'F')
  pdf.setFontSize(13)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Payé', 12, 223)
  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(10)

  pdf.text('Taux horaire payé (brut)', 12, 232)
  pdf.text(contract.TauxHorairePaye + 'Eur/H', 80, 232)

  height = 235
  for (let x of contract.TabPrimes) {
    if (x.TauxPayeImposable) {
      pdf.text(x.TxtPrime, 12, height)
      pdf.text(x.TauxPayeImposable.toString() + 'Eur', 80, height)
      height += 3
    }
  }
  pdf.setFontSize(9)
  pdf.text('IFM:10% ICP:10%', 12, height)
  pdf.setFontSize(6)
  pdf.text('Payé par virement le 12 du mois suivant', 12, height + 3)
  pdf.rect(10, 218, 93, height + 6 - 218)

  pdf.rect(107, 218, 93, 50)
  pdf.setFontSize(8)

  pdf.text(
    "Au cas où la mission s'effectue hors du territoire métropolitain, le",
    109,
    223,
  )
  pdf.text(
    "rapatriement du salarié est à la charge de l'ETT, à l'exception du",
    109,
    226,
  )
  pdf.text(
    'cas où le salarié rompt volontairement sa mission avant le terme',
    109,
    229,
  )

  pdf.text(
    "prévu du contrat. L'embauche par l'utilisateur à l'issue de la",
    109,
    232,
  )

  pdf.text(
    "mission n'est pas interdite sous réserve des interdictions fixées",
    109,
    235,
  )
  pdf.text(
    "à l'article L.1251-36 du code du travail. Les informations concernant",
    109,
    238,
  )

  pdf.text(
    'le motif, la durée, la rémunération et les caractéristiques particulières',
    109,
    241,
  )

  pdf.text(
    "du poste de travail, sont données sous la responsabilité de l'utilisateur,",
    109,
    244,
  )

  pdf.text(
    'seul habilité à les justifier. Il peut donc vous être délivré, à votre',
    109,
    247,
  )
  pdf.text(
    'demande et en fin de mission une attestation Pôle Emploi.    ',
    109,
    250,
  )

  const adresseVMArray = splitStringWithoutCuttingWords(
    'Visite médicale : ' + contract.TxtAdresseVM.replaceAll('\r\n', ' '),
    70,
  )

  for (let i = 0; i < adresseVMArray.length; i++) {
    pdf.text(adresseVMArray[i], 109, 256 + i * 3)
  }

  pdf.rect(10, 270, 93, 25)

  pdf.text(
    'Fait à Asnieres sur seine, le ' +
      formatDate(contract.DateHeureCRE.substring(0, 8)),
    12,
    273,
  )
  pdf.rect(107, 270, 93, 25)

  pdf.setTextColor(254, 0, 118)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Contrat à signer sous 48H', 160, 273)
  pdf.setFont('Helvetica', 'normal')
  pdf.setTextColor(0, 0, 0)

  pdf.text("Signature de l'interimaire", 120, 276)
  pdf.text(userData.AdresseVille.toUpperCase(), 160, 276)

  return pdf
}

export const createPdf = async (contract, pdf) => {


  console.log(contract);
  const [userData, clientData] = await Promise.all([
    axios.get(
      `tempworker-candidate/${contract.IdInterimaire}/tempoInterimaire`,
    ),
    axios.get(`tempworker-candidate/${contract.IdClient}/tempoClient`),
  ])
    .then(([userRes, clientRes]) => [userRes.data[0], clientRes.data[0]])
    .catch((e) => message.error("Une erreur s'est produite"))

  const img = new Image()
  img.src = myImage

  pdf.addImage(img, 'PNG', 0, 5, 50, 30)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(12)

 
  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text("J'm intérim SAS", 45, 10)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text("J'm Capeneo", 45, 10)
  else 
    pdf.text("J'm Capeneo Santé", 45, 10)


  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(7)

  pdf.text('Capital de 80 000', 45, 17)


  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('4 avenue Laurent Cély - 92600 Asnières sur Seine ', 45, 20)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('26 Bis Avenue Ribot - 19100 Brive La Gaillarde', 45, 20)
  else
    pdf.text('64 Avenue Benjamin Baillaud - 31500 Toulouse', 45, 20)


  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('T : 01.80.88.21.35 - @ : recrutement@jm-interim.com', 45, 23)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('T : 05.55.17.49.79 - @ : brive@jm-interim.com', 45, 23)
  else
    pdf.text('T : 05.61.11.11.00 - @ : sante@jm-interim.com', 45, 23)  
  
  
  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('SIRET : 919 125 518 00010 APE : 7820z', 45, 26)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('SIRET : 919 125 518 00028 APE : 7820z', 45, 26)
  else
  pdf.text('SIRET : 919 125 518 00036 APE : 7820z', 45, 26)

  if(contract.agency._id==="650adee7951dd697c7535e3e")
    pdf.text('URSSAF : 117000001571042223 Montreuil (93)', 45, 29)
  else if(contract.agency._id==="6533fe25099978decf532a59")
    pdf.text('URSSAF : 747000000931755615 Limousin (19)', 45, 29)
  else
    pdf.text('URSSAF : 737000000185770617 Toulouse (31)', 45, 29)







  pdf.text('Convention collective (IDCC 2378 ) ', 45, 32)
  pdf.text(
    'Personnel intérimaire des entreprises de travail temporaire',
    45,
    35,
  )
  pdf.text('Garant financier (art. L.1251-49 du CT) : Atradius', 45, 38)
  pdf.text('159 Rue Anatole France, 92300 Levallois-Perret', 45, 41)
  pdf.text(
    'Intérimaires Prévoyance, TSA 60008, 92 599 Levallois-Perret Cedex',
    45,
    44,
  )

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(12)

  pdf.text('Contrat', 160, 10)

  pdf.setFontSize(10)

  pdf.setFont('Helvetica', 'Bold')
  pdf.text(contract.NumeroContrat.toString(), 180, 10)

  pdf.setFont('Helvetica', 'normal')
  pdf.setFontSize(12)

  pdf.text('Initial', 160, 16)

  pdf.setFont('Helvetica', 'Bold')
  pdf.setFontSize(12)

  pdf.setTextColor(0, 196, 254)

  pdf.text('Contrat de mise à disposition', 130, 30)

  pdf.setTextColor(0, 0, 0)

  pdf.setDrawColor(0, 196, 254)

  pdf.line(130, 31, 190, 31)

  pdf.setDrawColor(0, 0, 0)

  pdf.roundedRect(10, 45, 6, 25, 1, 1)
  pdf.setFontSize(10)
  pdf.text('Interimaire', 15, 67, null, 90)

  pdf.roundedRect(18, 45, 85, 25, 1, 1)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text(contract.RaisonSociale, 117, 50)

  pdf.setFont('Helvetica', 'normal')

  pdf.text(clientData.AdresseRue, 117, 54)
  pdf.text(
    clientData.AdresseCodePostal + ' ' + clientData.AdresseVille,
    117,
    58,
  )

  pdf.roundedRect(107, 45, 6, 25, 1, 1)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Client', 112, 63, null, 90)
  pdf.setFont('Helvetica', 'normal')

  pdf.roundedRect(115, 45, 85, 25, 1, 1)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text(`${userData.Nom} ${userData.Prenom}`, 20, 50)

  pdf.setFont('Helvetica', 'normal')

  pdf.text(userData.AdresseRue, 20, 54)
  pdf.text(userData.AdresseCodePostal + ' ' + userData.AdresseVille, 20, 58)

  pdf.setFontSize(8)
  //------------------------------------------------------------------
  pdf.rect(10, 72, 93, 35)

  pdf.text('Nº matricule :', 12, 77)
  pdf.text(contract.IdInterimaire.toString(), 30, 77)
  pdf.text('Nationalité :', 12, 80)
  pdf.text(toCamelCase(userData.TxtNationalite.toString()), 30, 80)
  pdf.text('Né(e) le :', 12, 83)
  pdf.text(
    formatDate(userData.DateNaissance) +
      ' à ' +
      userData.LieuNaissance +
      ' (' +
      userData.TxtPaysNaissance +
      ')',
    30,
    83,
  )
  pdf.text('Qualification :', 12, 86)
  const contractProfession =
    contract.PCS[0] === '1' ||
    contract.PCS[0] === '2' ||
    contract.PCS[0] === '3'
      ? contract.Profession + '(Cadre) PCS : ' + contract.PCS
      : contract.Profession + '(Non Cadre) PCS : ' + contract.PCS
  pdf.text(contractProfession.substring(0, 40), 30, 86)
  if (contractProfession.length > 40)
    pdf.text(contractProfession.substring(40), 30, 89)
  pdf.text('Identité :', 12, 92)
  const ids = [1, 3, 4, 5]
  const tabCartes = userData.TabCartes.filter((obj) =>
    ids.includes(obj.IdCarte),
  )
  const idFile = tabCartes[0]
  if (idFile) {
    pdf.text(
      idFile.TxtCarte +
        ' : ' +
        idFile.Reference1 +
        ' Fin : ' +
        formatDate(idFile.DateFin),
      30,
      92,
    )
  }
//-----------------------------------------------------------------------


//_-----------------------------------------------------------------------
  pdf.rect(107, 72, 93, 35)
  pdf.text('Nº Client :', 109, 77)
  pdf.text(contract.IdClient.toString(), 129, 77)

  pdf.text('Contact :', 109, 80)
  pdf.text(contract.Interlocuteur, 129, 80)

  pdf.text('Lieu mission :', 109, 83)
  pdf.text(contract.AdresseRue.substring(0,40), 129, 83)
  if (contract.AdresseSuite.length) {
    pdf.text(contract.AdresseSuite, 129, 86)
    pdf.text(contract.AdresseCodePostal + ' ' + contract.AdresseVille, 129, 89)
  } else {
    pdf.text(contract.AdresseCodePostal + ' ' + contract.AdresseVille, 129, 92)
  }

  pdf.text("Moyen d'accès :", 109, 95)
  pdf.text('Téléphone :', 109, 98)
  pdf.text(clientData.Telephone1, 129, 98)

  pdf.text('Siret :', 109, 101)
  pdf.text(clientData.SIRET, 129, 101)

  pdf.text('NAF :', 167, 101)
  pdf.text(clientData.NAF, 175, 101)
  pdf.text('Effectif :', 109, 104)
  pdf.text(clientData.TxtEffectif, 129, 104)

  pdf.text('Durée Col :', 167, 104)

  //--------------------------------------------------------------------------

  //-------------------------------------------------------------------------

  pdf.rect(10, 109, 93, 11)
  pdf.text('Motif :', 12, 112)

  let [firstSencenceRecours, ...recoursArray] = splitStringWithoutCuttingWords(
    recours[contract.IdRecours].replaceAll('\r\n', ''),
    60,
  )
  pdf.text(firstSencenceRecours, 21, 112)

  recoursArray = splitStringWithoutCuttingWords(recoursArray.join(' '), 70)
  for (let i = 0; i < recoursArray.length; i++) {
    pdf.text(recoursArray[i], 11, 115 + i * 3)
  }

  pdf.rect(107, 109, 93, 11)
  pdf.text('Justificatif :', 109, 112)

  let [firstSencenceJustif, ...justifArray] = splitStringWithoutCuttingWords(
    contract.TxtJustificatif.replaceAll('\r\n', ' ').replaceAll('\r', ' '),
    50,
  )
  pdf.text(firstSencenceJustif, 123, 112)

  justifArray = splitStringWithoutCuttingWords(justifArray.join(' '), 70)
  for (let i = 0; i < justifArray.length; i++) {
    pdf.text(justifArray[i], 110, 115 + i * 3)
  }


  //----------------------------------------------------------------------------------

  pdf.rect(10, 121, 93, 75)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Dates', 11, 124)
  pdf.text(formatDate(contract.DateDebut), 25, 124)
  pdf.text(formatDate(contract.DateFin), 45, 124)
  pdf.text('au', 40, 124)
  pdf.text('inclus *', 65, 124)


  pdf.text('Souplesse', 11, 127)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(formatDate(contract.DateDebutSouplesse), 30, 127)
  pdf.text(formatDate(contract.DateFinSouplesse), 50, 127)
  pdf.text('au', 45, 127)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Durée hebdo', 11, 130)
  pdf.text(parseFloat(contract.NbHeureHebdo).toFixed(2) + '  Hrs', 30, 130)

  pdf.text("Période d'essai", 50, 133)

  pdf.text(contract.NbJoursEssai + '  JT', 72, 133)

  pdf.text('Horaires', 11, 136)
  pdf.text(
    contract.HoraireAMDebut.substring(0, 2) +
      'H' +
      contract.HoraireAMDebut.substring(2) +
      '-' +
      contract.HoraireAMFin.substring(0, 2) +
      'H' +
      contract.HoraireAMFin.substring(2),
    30,
    136,
  )
  pdf.text('Retraite', 11, 139)

  pdf.setFont('Helvetica', 'normal')

  pdf.text('REUNI 33 quai du Pdt Paul Doumer 92672 COURBEVOIE', 25, 139)
  pdf.text('cedex.', 11, 142)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Equip de securité: EU: ', 11, 145)
  pdf.setFont('Helvetica', 'normal')
 
  pdf.text(contract.TabEPIs.map((epi) => epi.TxtEPI).join(', '), 42, 145)
  const textRetraite =
    "*Le terme de la mission prévu dans le contrat initial ou dans l'avenant de prolongation peut être aménagé dans les conditions prévues aux articles L.1251-30 et L.1251-31."
  const textRetraiteArray = splitStringWithoutCuttingWords(textRetraite, 70)
  let height = 148;
  for (let i = 0; i < textRetraiteArray.length; i++) {
    pdf.text(textRetraiteArray[i], 11, height);
    height+=3;
  }

  const textConsigneQualification = splitStringWithoutCuttingWords(contract.TxtConsigneQualification, 70);
  for (let i = 0; i < textConsigneQualification.length; i++) {
    pdf.text(textConsigneQualification[i], 11, height + i * 3)
  }

  pdf.rect(107, 121, 93, 75)

  pdf.setFont('Helvetica', 'Bold')
  pdf.text('Caractéristiques du poste', 108, 124)

  pdf.setFont('Helvetica', 'normal')

  console.log(contract.TxtCaracteristiquePoste)

  let [firstSencence, ...caracteristiqueArray] = splitStringWithoutCuttingWords(
    contract.TxtCaracteristiquePoste.replaceAll('\r\n', '').replaceAll('\n', ''),
    40,
  )

  pdf.text(firstSencence, 146, 124)
  height = 127
  caracteristiqueArray = splitStringWithoutCuttingWords(
    caracteristiqueArray.join(' '),
    70,
  )
  for (let x of caracteristiqueArray) {
    pdf.text(x, 108, height)
    height += 3
  }

  pdf.setFont('Helvetica', 'Bold')
  pdf.text('Risques professionnels', 108, height)
  pdf.setFont('Helvetica', 'normal')
  pdf.text("Ce poste n'est pas à risque selon articles", 142, height)
  height += 3
  pdf.text('du code du travail en vigueur (dont L.4154-2)', 108, height)
  height += 3
  pdf.setFont('Helvetica', 'Bold')
  pdf.text('Facteurs pénibilité : ', 108, height)
  let penib = ''
  contract.PenibiliteNC === 'O' ? (penib = 'Non communiqué') : (penib = '')
  pdf.setFont('Helvetica', 'normal')
  pdf.text(' (art. L.4161-1 et R.4163-7 du CT) ' + penib, 133, height)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFont('Helvetica', 'Bold')
  height += 3

  pdf.text('Reférence de commande', 108, height)
  height += 3
  pdf.text('Salaire de référence ', 108, height)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(contract.TauxHoraireRef + 'EUR', 140, height)
  height += 3

  const consigneArray = splitStringWithoutCuttingWords(
    contract.TxtConsignePoste.replaceAll('\r\n', ''),
    70,
  )
  for (let x of consigneArray) {
    pdf.text(x, 108, height)
    height += 3
  }

  pdf.setFillColor(0, 196, 254)
  pdf.rect(107, 199, 93, 69)
  pdf.rect(107.1, 199.1, 92.8, 8, 'F')
  pdf.setFontSize(13)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Facturé', 109, 204)
  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(10)

  pdf.text('Taux horaire facturé (HT)', 109, 213)
  pdf.text(contract.TauxHoraireFacture + ' Eur/H', 177, 213)

  pdf.setFontSize(8)


  height = 216
  //pdf.text(contract.TxtConsigneFacturation,109,height)
  for(let x of contract.TxtConsigneFacturation.split("\n")){
    pdf.text(x, 109, height)
    height += 3

  }
  for (let x of contract.TabPrimes) {
    if (x.TauxPayeImposable) {
      pdf.text(x.TxtPrime, 109, height)
      pdf.text(x.TauxFacture.toString() + ' Eur', 177, height)
      height += 3
    }
  } 
  pdf.setFontSize(8)
  pdf.text('Règlement:Virement ' + clientData.TxtEcheance, 109, height)

  if(contract.agency._id==="650adee7951dd697c7535e3e")
  {
    pdf.text(
      "Adr.V.M.:Horizon Santé Travail, 4 avenue Laurent Cély, Tour d'Asnières, Hall B, 1er étage,",
      109,
      height + 3,
    )
    pdf.text('92600 Asnieres', 109, height + 6)
  }
  else if(contract.agency._id==="6533fe25099978decf532a59")
  {
    pdf.text(
      "Adr.V.M.:9 rue Louis Taurisson, 19100 Brive",
      109,
      height + 3,
    )
  }
  else
    {
      pdf.text(
        "Adr.V.M.:8 Rue Henri Jansou, 31200 Toulouse",
        109,
        height + 3,
      )
    }

  

  pdf.rect(10, 199, 93, 69)

  let clientTextHeight = 206
  let clientText =
    "Dans le cas où l'accès aux équipements collectif est assuré (art. L.1251-24), les frais correspondants sont pris en charge par l'entreprise utilisatrice qui, le cas échéant, remboursera au comité d'entreprise. L'embauche par l'utilisateur à l'issue de la mission n'est pas interdite, sous réserve des interdictions fixées à l'article L.1251-36 du code du travail. Les informations concernant le motif, la durée, la rémunération et les caractéristiques particulières du poste de travail sont données sous la responsabilité de l'utilisateur, seul habilité à les justifier.Les jours fériés dans l'entreprise utilisatrice sont dûs aux salariés temporaires dans les mêmes conditions que les salariés permanents, à l'exclusion de toute condition d'ancienneté. L'utilisateur soussigné déclare avoir pris connaissance des conditions générales de prestation au dos, partie intégrante du présent contrat, et les accepter, notamment en ce qui concerne le §1."
  const clientTextArray = splitStringWithoutCuttingWords(clientText, 70)
  for (let x of clientTextArray) {
    pdf.text(x, 11, clientTextHeight)
    clientTextHeight += 3
  }

  /* 
    const adresseVMArray = splitStringWithoutCuttingWords(contract.TxtAdresseVM.replaceAll('\r\n', ' '),60);
    
    for(let i=0;i<adresseVMArray.length;i++){
      pdf.text(adresseVMArray[i],12,256 +i*3);
    }

 */

  pdf.rect(10, 270, 93, 25)
  let imageData = 'data:image/png;base64,' + contract.agency.signatureImg
  pdf.addImage(imageData, 'PNG', 45, 270, 50, 20)
  pdf.text(
    'Fait à Asnieres sur seine, le ' +
      formatDate(contract.DateHeureCRE.substring(0, 8)),
    12,
    273,
  )
  pdf.text(
    `signataire : ${contract.creator?.firstname||"Jihade"} ${contract.creator?.lastname||"El mallouli"}`,
    12,
    277,
  )

  pdf.rect(107, 270, 93, 25)

  pdf.setTextColor(0, 196, 254)
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Contrat à signer sous 48H', 160, 273)
  pdf.setFont('Helvetica', 'normal')
  pdf.setTextColor(0, 0, 0)

  pdf.text('Signature du client', 120, 276)
  pdf.text(userData.AdresseVille.toUpperCase(), 160, 276)

  return pdf
}

export const createVacationPdf = async (contract, pdf) => {
  const img = new Image()
  img.src = myImage

  pdf.addImage(img, 'PNG', 0, 10, 50, 30)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(14)

  pdf.text("J'm intérim SAS", 45, 15)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(9)

  pdf.text('Capital de 80 000', 45, 22)
  pdf.text('4 avenue Laurent Cély - 92600 Asnières sur Seine ', 45, 26)
  pdf.text('T : 01.80.88.21.35 - @ : recrutement@jm-interim.com', 45, 30)
  pdf.text('SIRET : 919 125 518 00010 APE : 7820z', 45, 34)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFontSize(14)

  pdf.text('Contrat', 160, 15)

  pdf.setFontSize(12)

  pdf.setFont('Helvetica', 'Bold')
  pdf.text(contract.contractNumber.toString(), 180, 15)

  pdf.setFont('Helvetica', 'normal')

  pdf.setFont('Helvetica', 'Bold')
  pdf.setFontSize(14)

  pdf.setTextColor(0, 196, 254)

  pdf.text('Contrat de prestation de service', 125, 35)

  pdf.setTextColor(0, 0, 0)

  pdf.setDrawColor(0, 196, 254)

  pdf.line(125, 36, 200, 36)

  pdf.setDrawColor(0, 0, 0)

  pdf.roundedRect(10, 45, 6, 73, 1, 1)
  pdf.setFontSize(12)
  pdf.text('Vacataire', 15, 90, null, 90)

  pdf.roundedRect(18, 45, 85, 35, 1, 1)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text(contract.client.companyName, 117, 52)

  pdf.setFont('Helvetica', 'normal')

  pdf.text(contract.client.address, 117, 60)
  pdf.text(contract.client.postCode + ' ' + contract.client.town || '', 117, 68)

  pdf.roundedRect(107, 45, 6, 73, 1, 1)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('Client', 112, 90, null, 90)
  pdf.setFont('Helvetica', 'normal')

  pdf.roundedRect(115, 45, 85, 35, 1, 1)

  pdf.setFont('Helvetica', 'Bold')

  pdf.text(
    `${contract.candidate.lastname} ${contract.candidate.firstname}`,
    20,
    52,
  )

  pdf.setFont('Helvetica', 'normal')

  if (contract.candidate.address) pdf.text(contract.candidate.address, 20, 60)

  console.log(contract.candidate.postCode)
  console.log(contract.candidate.town)
  console.log(contract.candidate.address)

  if (contract.candidate.postCode && contract.candidate.town) {
    pdf.text(
      contract.candidate.postCode + ' ' + contract.candidate.town,
      20,
      68,
    )
  }

  pdf.setFontSize(10)

  pdf.roundedRect(18, 83, 85, 35, 1, 1)

  pdf.text('Poste :', 20, 88)
  pdf.text(contract.qualification, 32, 88)
  pdf.text('Date de naissance :', 20, 100)
  pdf.text(formatJsDate(contract.candidate.dob), 55, 100)

  pdf.roundedRect(115, 83, 85, 35, 1, 1)
  pdf.text('N° Client :', 117, 88)
  pdf.text(parseInt(Math.random() * 10000) + '', 147, 88)
  pdf.text('Contact :', 117, 93)
  pdf.text(
    `${contract.client.contactFirstname} ${contract.client.contactLastname} `,
    147,
    93,
  )
  pdf.text('Lieu de mission :', 117, 98)
  if (contract.order) {
    pdf.text(contract.order.address || '', 147, 98)
    pdf.text(
      `${contract.order.postCode || ''}, ${contract.order.town || ''} `,
      137,
      103,
    )
  } else {
    pdf.text(contract.address, 147, 98)
    pdf.text(`${contract.postCode}, ${contract.town || ''}`, 137, 103)
  }

  pdf.text('Téléphone :', 117, 108)
  if (contract.client.contactPhone) {
    pdf.text(
      contract.client.contactPhone
        .match(/.{1,2}/g)
        .join('')
        .replaceAll(' ', ''),
      147,
      108,
    )
  }
  pdf.text('Siret :', 117, 113)
  pdf.text(contract.client.siret, 147, 113)

  pdf.rect(10.3, 128, 189.7, 60)

  pdf.setFillColor(0, 196, 254)
  pdf.rect(10, 120, 190, 10, 'F')

  pdf.setTextColor(255, 255, 255)
  pdf.text('VACATION', 18, 126)
  pdf.setTextColor(0, 0, 0)

  pdf.text('Dates :', 20, 140)
  pdf.setFont('Helvetica', 'Bold')
  let height = 140
  for (let date of contract.dates) {
    /*  if (date.startHour) {
      pdf.text(
        formatJsDate(date.dateDebut) +
          ' de ' +
          new Date(date.startHour).getHours().toString().padStart(2, '0') +
          ':' +
          new Date(date.startHour).getMinutes().toString().padStart(2, '0') +
          ' à ' +
          new Date(date.endHour).getHours().toString().padStart(2, '0') +
          ':' +
          new Date(date.endHour).getMinutes().toString().padStart(2, '0'),
        35,
        height,
      )
    } else { */
    pdf.text(formatJsDate(date.dateDebut), 35, height)
    //}
    height += 5
  }
  pdf.text('Caracteristiques du poste :', 120, 140)
  pdf.setFont('Helvetica', 'normal')
  const justificatifArray = splitStringWithoutCuttingWords(
    contract.justificatif,
    45,
  )
  let justifHeight = 145
  for (let justif of justificatifArray) {
    pdf.text(justif, 120, justifHeight)
    justifHeight += 4
  }
  pdf.setFontSize(8)
  pdf.setFont('Helvetica', 'Bold')
  pdf.text('/!\\ La securité avant tout : ', 20, 177)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(
    'Port des EPI obligatoire. Le respect des règles de sécurité et',
    20,
    180,
  )
  pdf.text("d'hygiène est obligatoire.", 20, 183)

  pdf.setFont('Helvetica', 'normal')
  pdf.setFontSize(10)
  pdf.setTextColor(0, 0, 0)

  pdf.rect(10.3, 190, 189.7, 65)
  pdf.setFillColor(0, 196, 254)
  pdf.rect(10, 190, 190, 10, 'F')

  pdf.setTextColor(255, 255, 255)
  pdf.setFont('Helvetica', 'Bold')
  pdf.text('FACTURATION (H.T.)', 18, 196)
  pdf.setTextColor(0, 0, 0)

  pdf.setFont('Helvetica', 'normal')

  height = 210

  pdf.text('TVA en vigueur : 20%', 120, 210)
  pdf.text('Règlement :Virement 30 jrs fdm', 120, 215)

  for (let fact of contract.facturation) {
    pdf.text(fact.quantity + ' * ' + fact.description, 20, height)
    pdf.text(
      parseFloat(fact.quantity) * parseFloat(fact.rate) + '€',
      80,
      height,
    )
    height += 5
  }

  /* pdf.text('1/ OBJET DU CONTRAT', 14, 185)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(
    'Ce contrat établit une prestation de service visant à offrir des services de recrutement et à',
    14,
    187,
  )
  pdf.text(
    'faciliter la mise en relation avec des vacataires afin de satisfaire les besoins de nos clients.',
    14,
    189,
  )
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('2/ TARIF', 14, 192)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(
    "Les tarifs convenus entre l'agence et le client seront détaillés dans un document distinct, et la",
    14,
    194,
  )
  pdf.text(
    "facturation s'effectuera conformément aux modalités spécifiées dans ledit document.",
    14,
    196,
  )
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('3/ RÈGLEMENT–PÉNALITÉS', 14, 199)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(
    'Nos factures sont payables au comptant, sauf accord contraire précisé sur la facture. Au tarif',
    14,
    201,
  )
  pdf.text(
    'hors taxes figurant au présent contrat, s’ajoute la T.V.A. Toute facture impayée pourra entraîner',
    14,
    203,
  )
  pdf.text(
    'de notre part la suspension de nos prestations en cours, sans qu’il soit nécessaire de procéder',
    14,
    205,
  )
  pdf.text(
    'à une mise en demeure. De convention expresse, le non-respect des conditions de règlement',
    14,
    207,
  )
  pdf.text(
    'entraîne, sans préjudice de toute autre voie d’action, l’application de plein droit de',
    14,
    209,
  )
  pdf.text(
    'pénalités de retard d’un montant égal au taux de refinancement de la Banque centrale',
    14,
    211,
  )
  pdf.text(
    'européenne, majoré de 7points, conformément à l’article L. 441-6 du code de commerce,',
    14,
    213,
  )
  pdf.text(
    'prenant effet au lendemain de la date de paiement figurant sur la facture. Lorsque le crédit de',
    14,
    215,
  )
  pdf.text(
    'l’utilisateur se détériore, nous nous réservons le droit, même après le début d’exécution d’une',
    14,
    217,
  )
  pdf.text(
    'commande, d’exiger du client les garanties que nous jugeons convenables en vue de la',
    14,
    219,
  )
  pdf.text(
    'bonne exécution des engagements pris. Le refus d’y satisfaire nous donne le droit d’annuler',
    14,
    221,
  )
  pdf.text('tout ou partie de la commande.', 14, 223)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('4/ RESPONSABILITE CIVILE', 14, 226)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(
    "Le client est civilement responsable, en tant qu'employeur du personnel placé sous sa",
    14,
    228,
  )
  pdf.text(
    'direction exclusive de tous les dommages causés à des tiers sur les lieux ou à l’occasion du',
    14,
    230,
  )
  pdf.text(
    'travail. Notre société se trouve dégagée de toute responsabilité quant aux dommages de',
    14,
    232,
  )
  pdf.text(
    'quelque nature qu’ils soient de caractère professionnel ou non causés par ledit personnel',
    14,
    234,
  )
  pdf.text(
    'temporaire et résultant, entre autres, d’une absence ou d’une insuffisance de contrôle ou',
    14,
    236,
  )
  pdf.text('d’encadrement comme de l’inobservation des règlements.', 14, 238)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('5/ COMPÉTENCE', 14, 241)
  pdf.setFont('Helvetica', 'normal')

  pdf.text(
    'De convention expresse et en cas de contestation, les tribunaux du lieu du siège social',
    14,
    243,
  )
  pdf.text(
    'de l’agence, sont seuls compétents pour connaître les différends d’interprétation et',
    14,
    245,
  )
  pdf.text('d’exécution pouvant découler des présentes prestations.', 14, 247)
  pdf.setFont('Helvetica', 'Bold')

  pdf.text('6/ CLAUSES PÉNALES', 14, 250)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(
    'Passé un délai de 15 jours après une mise en demeure, le défaut de paiement de nos',
    14,
    252,
  )
  pdf.text(
    'prestations entraînera de plein droit la majoration des sommes dues, selon les modalités de la',
    14,
    254,
  )
  pdf.text(
    'Banque centrale européenne. Tous les frais de procédure et d’honoraires que l’ETT aura eu à',
    14,
    256,
  )
  pdf.text(
    'exposer en vue du recouvrement de ses factures seront à la charge de l’utilisateur.',
    14,
    258,
  )


pdf.setFont("Helvetica","Bold")
pdf.text("7/ CONFIDENTIALITE",105,185)
pdf.setFont("Helvetica","normal")

pdf.text("Les deux parties conviennent de traiter comme strictement confidentielles toutes les",105,187)
pdf.text("informations, données et documents fournis par l'autre partie dans le cadre de ce contrat.",105,189)
pdf.text("Aucune des parties ne divulguera, ne reproduira ni n'utilisera ces informations à des fins autres",105,191)
pdf.text("que celles prévues par le présent contrat, sans le consentement écrit préalable de l'autre partie.",105,193)
pdf.setFont("Helvetica","Bold")

pdf.text("8/ EMBAUCHE",105,196)
pdf.setFont("Helvetica","normal")

pdf.text("L'embauche par l'utilisateur à l'issue de la vacation n'est pas interdite, sous réserve des",105,198)
pdf.text("interdictions fixées à l'article L.1251-36 du code du travail. . Toutefois, si l'intérimaire ne",105,200)
pdf.text("satisfait pas au nombre minimum d'heures spécifié dans le devis, des frais seront facturés",105,202)
pdf.text("afin de couvrir les dépenses engagées pour le recrutement, les moyens de communication",105,204)
pdf.text("utilisés, les frais liés à l'utilisation des plateformes d'offres d'emploi, et tout autre dépense",105,206)
pdf.text("connexe.",105,208)
pdf.setFont("Helvetica","Bold")

pdf.text("9/ RESPONSABILITE EMPLOYEUR",105,211)
pdf.setFont("Helvetica","normal")

pdf.text("L'employeur est le client. L'agence met seulement en relation le vacataire et le client.",105,213)
pdf.text("Tous les documents administratifs sont à établir par le client (DPAE, contrat, fiche de paie et",105,215)
pdf.text("solde de tout compte, vérification de la conformité des papiers d'identité, etc...)",105,217)
pdf.text("Le présent contrat et ses annexes contiennent tous les engagements des parties, et les",105,219)
pdf.text("correspondances, offres ou propositions antérieures à la signature des présentes, sont",105,221)
pdf.text("considérées comme non-avenues.",105,223)
pdf.text("L'utilisateur soussigné déclare avoir pris connaissance des conditions générales de prestation, et",105,225)
pdf.text("les accepter",105,227) */

  pdf.rect(10, 260, 90, 35)
  let imageData = 'data:image/png;base64,' + contract.creator?.signatureImg
  if (contract.creator?.signatureImg) {
    pdf.addImage(imageData, 'PNG', 45, 270, 50, 20)
  }
  pdf.setFontSize(9)
  pdf.text(
    'Fait à ASNIERES/SEINE, le ' + formatJsDate(contract.createdAt),
    12,
    265,
  )
  pdf.text("Signature de l'agence,", 12, 268)
  pdf.setFont('Helvetica', 'Bold')
  pdf.text(
    contract.creator.firstname + ' ' + contract.creator.lastname,
    12,
    272,
  )
  pdf.setFont('Helvetica', 'normal')
  pdf.rect(110, 260, 90, 35)

  pdf.text(
    `Fait à ${contract.client.town || ''} le ${formatJsDate(
      contract.createdAt,
    )}`,
    112,
    265,
  )
  pdf.setTextColor(0, 196, 254)
  pdf.text(`Contrat à signer sous 48H`, 162, 268)
  pdf.setTextColor(0, 0, 0)
  pdf.text('Signature du client,', 112, 268)

  pdf.addPage()
  pdf.addImage(cgvVacation, 'PNG', 10, 10, 198, 282)

  return pdf
}

function splitStringWithoutCuttingWords(inputString, chunkSize) {
  const words = inputString.split(' ')
  const resultChunks = []
  let currentChunk = ''

  for (const word of words) {
    if (currentChunk.length + word.length <= chunkSize) {
      currentChunk += (currentChunk ? ' ' : '') + word
    } else {
      resultChunks.push(currentChunk)
      currentChunk = word
    }
  }

  if (currentChunk) {
    resultChunks.push(currentChunk)
  }

  return resultChunks
}

const formatJsDate = (date) => {
  const newDate = new Date(date)
  const day = String(newDate.getDate()).padStart(2, '0')
  const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
  const year = newDate.getFullYear()

  return `${day}/${month}/${year}`
}

const formatJsHour = (date) => {
  const newDate = new Date(date)

  const hours = String(newDate.getHours()).padStart(2, '0')
  const minutes = String(newDate.getMinutes()).padStart(2, '0')

  return `${hours}:${minutes}`
}

const formatDate = (inputDate) => {
  const year = inputDate.substring(0, 4)
  const month = inputDate.substring(4, 6)
  const day = inputDate.substring(6, 8)

  return `${day}/${month}/${year}`
}

function toCamelCase(input) {
  if (input) return input[0].toUpperCase() + input.substring(1)
  else return ''
}
