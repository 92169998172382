import { useEffect, useState } from 'react'
import axios from '../../axios/axiosInstance'
import {
  Button,
  Modal,
  message,
  Popover,
  DatePicker,
  Checkbox,
  Select,
  Input,
} from 'antd'
import xlsIcon from '../../assets/img/xls.png'
import SearchIcon from '@mui/icons-material/Search'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Puff } from 'react-loader-spinner'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import RelanceModal from './RelanceModal'

const Prolongation = () => {
  const [prolongations, setProlongations] = useState([])
  const [originalProlongations, setOriginalProlongations] = useState([])
  const [isCreateProlongation, setIsCreateProlongation] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedContract, setSelectedContract] = useState(null)
  const [isDemat, setIsDemat] = useState(false)
  const [selectedDemat, setSelectedDemat] = useState(false)
  const [isProlongation, setIsProlongation] = useState(false)
  const [isRenouvellement, setIsRenouvellement] = useState(false)
  const [isNewMotif, setIsNewMotif] = useState(false)
  const [newMotif, setNewMotif] = useState('')
  const [newJustificatif, setNewJustificatif] = useState('')
  const [nbHeureHebdo, setNbHeureHebdo] = useState('')
  const [NbJoursEssai, setNbJoursEssai] = useState('')
  const [currentDate, setCurrenDate] = useState(new Date())
  const [loadingProlongations, setLoadingProlongations] = useState(true)
  const [filter, setFilter] = useState('Toutes')
  const [currentWeekNumber, setCurrentWeekNumber] = useState(0)
  const [weeks, setWeeks] = useState([])
  const [finishDate, setFinishDate] = useState(null)
  const [isFinishDate, setIsFinishDate] = useState(false)
  const [query, setQuery] = useState('')
  const [isRelance, setIsRelance] = useState(false)
  const [prolongationsARelancer, setProlongationsARelancer] = useState([])

  function getISOWeekNumber(date) {
    const currentDate = date || new Date()
    const daysUntilMonday = (currentDate.getDay() + 6) % 7 // Calculate days until Monday
    const startOfWeek = new Date(currentDate.getFullYear(), 0, 1)

    // Adjust the start of the week to the preceding Monday
    startOfWeek.setDate(
      startOfWeek.getDate() + (daysUntilMonday > 0 ? 8 - daysUntilMonday : 1),
    )

    const diff = currentDate - startOfWeek
    const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000)) + 1 // Adding 1 to start from week 1

    return weekNumber - 1
  }

  const checkIsProlongation = (contractNumber) => {
    axios
      .get(`pld-contract/check-prolongation/${contractNumber}`)
      .then((res) => {
        const bool = res.data
        if (bool) {
          setIsProlongation(true)
          setIsRenouvellement(false)
        } else {
          setIsRenouvellement(true)
          setIsProlongation(false)
        }
      })
  }

  const finish = () => {
    axios
      .post('pld-contract/finish', {
        endDate: finishDate,
        contractId: selectedContract._id,
      })
      .then((res) => {
        const contractIdx = prolongations.findIndex(
          (prlg) => prlg._id === selectedContract._id,
        )
        const originalContractIdx = originalProlongations.findIndex(
          (prlg) => prlg._id === selectedContract._id,
        )
        const newProlongations = [...prolongations]
        newProlongations.splice(contractIdx, 1)
        setProlongations(newProlongations)

        const newOriginalProlongations = [...originalProlongations]
        newOriginalProlongations.splice(originalContractIdx, 1)
        setOriginalProlongations(newOriginalProlongations)
        setIsFinishDate(false)
        setFinishDate(null)
        setSelectedContract(null)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  const selectDemat = (bool) => {
    setIsDemat(bool)
    if (!selectedDemat) setSelectedDemat(true)
  }

  useEffect(() => {
    const currentWeekNumber = getISOWeekNumber()
    setCurrentWeekNumber(currentWeekNumber)
    const currentYear = new Date().getFullYear()
    const allWeeksOfYear = getWeeksOfYear(currentYear)
    setWeeks(allWeeksOfYear)
    loadProlongations(allWeeksOfYear[currentWeekNumber])
  }, [])

  function getWeeksOfYear() {
    const currentYear = new Date().getFullYear()
    const weeks = []

    const firstDayOfYear = new Date(currentYear, 0, 1)
    const lastDayOfYear = new Date(currentYear, 11, 31)

    // Find the first Monday of the year
    let currentWeekDate = new Date(firstDayOfYear)
    while (currentWeekDate.getDay() !== 1 /* Monday */) {
      currentWeekDate.setDate(currentWeekDate.getDate() + 1)
    }

    while (currentWeekDate <= lastDayOfYear) {
      const weekNumber = getISOWeekNumber(currentWeekDate)

      // Calculate the last day of the week (Sunday)
      const lastDayOfWeek = new Date(currentWeekDate)
      lastDayOfWeek.setDate(currentWeekDate.getDate() + 6)

      weeks.push({
        year: currentYear,
        weekNumber,
        firstDayOfWeek: currentWeekDate,
        lastDayOfWeek,
      })

      currentWeekDate = new Date(
        currentWeekDate.getTime() + 7 * 24 * 60 * 60 * 1000,
      )
    }

    return weeks
  }

  const formatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6, 8)

    return `${day}/${month}/${year}`
  }

  const usedMotif = [
    "42 : Accroissement temporaire d'activité lié à une variation cyclique d'activité",
    "52 : Accroissement temporaire d'activité lié à une tâche occasionnelle et non durable",
    "11 : Remplacement d'un salarié en cas d'absence ou de suspension de son contrat de travail",
    '83 : Emploi temporaire par nature défini comme emploi à caractère saisonnier',
    "93 : Emploi temporaire par nature défini comme emploi d'usage constant",
  ]

  const usedMotifArray = usedMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  })

  const createRenouvellement = () => {
    if (!startDate) {
      message.error('Veuillez selectionner la date de début !')
      return
    }

    if (!selectedDemat) {
      message.error('Voulez vous mettre le contrat en ligne ?')
      return
    }

    if (!endDate) {
      message.error('Veuillez selectionner la date de fin !')
      return
    }

    if (nbHeureHebdo.length === 0) {
      message.error("Veuillez entrer le nombre d'heures hebdomadaire !")
      return
    }

    if (NbJoursEssai.length === 0) {
      message.error("Veuillez entrer la periode d'essai !")
      return
    }

    try {
      axios
        .post('pld-contract/renouvellement', {
          startDate,
          endDate,
          isDemat,
          contract: {
            ...selectedContract,
            TxtRecours: newMotif,
            TxtJustificati: newJustificatif,
            NbJoursEssai,
            NbHeureHebdo: nbHeureHebdo,
          },
        })
        .then((res) => {
          console.log(res.data)
          message.success('Nouveau contrat crée !')
          setSelectedContract(null)
          setIsCreateProlongation(false)
        })
        .catch((e) => {
          console.log(e)
          message.error(
            "La nouvelle date de fin doit etre posterieure à l'actuelle date de fin ! ",
          )
        })
    } catch (e) {
      console.log(e)
    }
  }

  const createProlongation = () => {
    if (!selectedDemat) {
      message.error('Voulez vous mettre le contrat en ligne ?')
      return
    }
    if (!endDate) {
      message.error('Veuillez selectionner la nouvelle date de fin !')
      return
    }
    if (nbHeureHebdo.length === 0) {
      message.error("Veuillez entrer le nombre d'heures hebdomadaire !")
      return
    }
    axios
      .post('pld-contract/prolongation', {
        endDate,
        isDemat,
        pldContract: selectedContract._id,
        NbHeureHebdo: nbHeureHebdo,
      })
      .then((res) => {
        console.log(res.data)
        message.success('Prolongation traitée !')
        setSelectedContract(null)
        setIsCreateProlongation(false)
      })
      .catch((e) => {
        console.log(e)
        message.error(
          "La nouvelle date de fin doit etre posterieure à l'actuelle date de fin ! ",
        )
      })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const formatTempoDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${year}${month}${day}`
  }

  const loadProlongations = (week) => {
    setLoadingProlongations(true)
    const startDay = formatTempoDate(week.firstDayOfWeek)
    const lastDay = formatTempoDate(week.lastDayOfWeek)
    axios
      .get(`pld-contract/by-date/${startDay}/${lastDay}`)
      .then((res) => {
        console.log(res.data)
        setProlongations(res.data)
        setOriginalProlongations(res.data)
        setFilter('Toutes')
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoadingProlongations(false))
  }

  const handleExportClick = () => {
    exportToExcel(prolongations)
  }

  const exportToExcel = (data) => {
    const newData = prolongations.map((prolongation) => {
      return {
        'Numero contrat': prolongation.NumeroContrat,
        'Numero avenant': prolongation.NumeroAvenant,
        Client: prolongation.RaisonSociale,
        Interimaire: prolongation.NomPrenom,
        'Date du contrat':
          formatDate(prolongation.DateDebut) +
          '-' +
          formatDate(prolongation.DateFin),
        'Decision client': prolongation.decisionProlongationClient,
        Statut: prolongation.status,
      }
    })

    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(newData)

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'prolongations.xlsx')
  }

  const filterProlongations = (status) => {
    if (status === 'Toutes') {
      setProlongations(originalProlongations)
    }
    if (status === 'A prolonger') {
      setProlongations(
        originalProlongations.filter(
          (prol) => prol.decisionProlongationClient === 'prolongation',
        ),
      )
    }
    if (status === 'A renouveller') {
      setProlongations(
        originalProlongations.filter(
          (prol) => prol.decisionProlongationClient === 'renouvellement',
        ),
      )
    }
    if (status === 'A arreter') {
      setProlongations(
        originalProlongations.filter(
          (prol) => prol.decisionProlongationClient === 'fin',
        ),
      )
    }
  }

  const findByText = () => {
    axios
      .post(`pld-contract/prolongation/find-by-text/`, { query })
      .then((res) => {
        setProlongations(res.data)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  return (
    <div>
      {isRelance && (
        <RelanceModal
          prolongations={prolongationsARelancer}
          isRelance={isRelance}
          setIsRelance={setIsRelance}
        />
      )}

      {loadingProlongations && (
        <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#9061f9"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {isFinishDate && (
        <Modal
          width={500}
          open={isFinishDate}
          footer={null}
          onCancel={() => {
            setIsFinishDate(false)
            setSelectedContract(null)
          }}
        >
          <div className="flex flex-col mt-10 space-y-4 items-center justify-center">
            <p className="text-xl font-semibold">
              Contrat n° {selectedContract?.NumeroContrat}
            </p>
            <DatePicker
              className="w-96 p-2 mt-4"
              onChange={(date, dateString) => setFinishDate(date)}
              format={'DD/MM/YYYY'}
              placeholder="Selectionner la date de fin du contrat"
            />

            <button
              onClick={() => finish()}
              className="p-2 mt-4 text-white bg-purple-500 rounded-md w-96"
            >
              Terminer le contrat
            </button>
          </div>
        </Modal>
      )}

      {isCreateProlongation && (
        <Modal
          width={800}
          open={isCreateProlongation}
          footer={null}
          onCancel={() => {
            setIsCreateProlongation(false)
            setSelectedDemat(false)
            setIsDemat(false)
          }}
        >
          <div className=" mt-10 flex space-y-4 flex-col items-center justify-center">
            {isProlongation && (
              <div>
                <p className="font-semibold text-xl">
                  Selectionner la nouvelle date de fin de contrat
                </p>
                <DatePicker
                  className="w-96 p-2 mt-4"
                  onChange={(date, dateString) => setEndDate(date)}
                  format={'DD/MM/YYYY'}
                  placeholder="Selectionner la nouvelle date de fin"
                />

                <div className="flex mt-4 flex-col space-y-2">
                  <p className="text-xl font-semibold">
                    Nombre d'heures hebdomadaire
                  </p>
                  <input
                    className="border w-96 border-gray-300 rounded-md  p-2"
                    placeholder="Nombre d'heures hebdomadaire"
                    value={nbHeureHebdo}
                    onChange={(e) => setNbHeureHebdo(e.target.value)}
                  />
                </div>

                <p className="font-semibold text-xl my-4">
                  Souhaitez vous mettre le contrat en ligne ?
                </p>
                <div className="flex items-center justify-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <p>OUI</p>
                    <Checkbox
                      checked={selectedDemat && isDemat}
                      onChange={() => {
                        selectDemat(true)
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <p>NON</p>
                    <Checkbox
                      checked={selectedDemat && !isDemat}
                      onChange={() => {
                        selectDemat(false)
                      }}
                    />
                  </div>
                </div>

                <button
                  onClick={() => createProlongation()}
                  className="p-2 mt-4 text-white bg-purple-500 rounded-md w-96"
                >
                  Prolonger le contrat
                </button>
              </div>
            )}

            {isRenouvellement && (
              <div className="flex flex-col space-y-2 items-center justify-center">
                <p className="font-semibold text-xl">
                  Selectionner les dates du nouveau contrat
                </p>
                <p className="flex flex-col items-center justify-start space-y-2 w-96">
                  <span className="font-semibold uppercase">Date de début</span>
                  <DatePicker
                    className="w-48 my-2"
                    onChange={(date, dateString) => setStartDate(date)}
                    format={'DD/MM/YYYY'}
                    placeholder="Selectionner la date de début"
                  />
                </p>
                <p className="flex flex-col space-y-2 items-center space-x-2 justify-start w-96">
                  <span className="font-semibold uppercase">Date de fin</span>

                  <DatePicker
                    className="w-48 my-2"
                    onChange={(date, dateString) => setEndDate(date)}
                    format={'DD/MM/YYYY'}
                    placeholder="Selectionner date de fin"
                  />
                </p>

                <div className="flex mt-4 flex-col space-y-2 items-center justify-center">
                  <p className="text-xl font-semibold">
                    Nombre d'heures hebdomadaire
                  </p>
                  <input
                    className="border border-gray-300 rounded-md w-96 p-2"
                    placeholder="Nombre d'heures hebdomadaire"
                    value={nbHeureHebdo}
                    onChange={(e) => setNbHeureHebdo(e.target.value)}
                  />
                </div>

                <div className="flex mt-4 flex-col space-y-2 items-center justify-center">
                  <p className="text-xl font-semibold">Periode d'essai</p>
                  <input
                    className="border border-gray-300 rounded-md w-96 p-2"
                    placeholder="Nombre de jours d'essai"
                    value={NbJoursEssai}
                    onChange={(e) => setNbJoursEssai(e.target.value)}
                  />
                </div>

                <div className="flex flex-col   items-center justify-center">
                  <div className="border w-4/5 p-3 border-gray-300 shadow-xl my-10 rounded-md">
                    <p className="font-semibold text-xl mt-8 text-center">
                      Motif actuel :
                    </p>
                    <p className="text-lg  text-center mt-4">{newMotif} </p>
                    <p className="font-semibold text-xl mt-4 text-center">
                      Justificatif :
                    </p>
                    <p className="text-lg  text-center my-4">
                      {newJustificatif}{' '}
                    </p>
                  </div>
                  <p
                    onClick={() => setIsNewMotif(!isNewMotif)}
                    className="bg-purple-600 hover:cursor-pointer my-4 w-fit font-semibold text-white rounded-md  p-2"
                  >
                    Changer le motif{' '}
                  </p>

                  {isNewMotif && (
                    <div className="w-full flex items-center flex-col">
                      <p className="font-semibold text-center mb-2 text-3xl">
                        Motif de recours
                      </p>

                      <Select
                        className=" w-4/6 py-3 my-2 placeholder:text-gray-400"
                        showSearch
                        onChange={(e) => setNewMotif(e)}
                        value={newMotif}
                        optionFilterProp="children"
                        placeholder="Motif de recours"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={usedMotifArray}
                      />
                      <textarea
                        onChange={(e) => {
                          setNewJustificatif(e.target.value)
                        }}
                        value={newJustificatif}
                        placeholder="Justificatif (Ex : nom d'un client, retard d'un chantier, etc.)"
                        className="h-24 my-4 w-4/6  placeholder:text-gray-400 outline-none border border-gray-300 rounded-md"
                      />
                    </div>
                  )}
                </div>

                <p className="font-semibold text-xl my-4">
                  Souhaitez vous mettre le contrat en ligne ?
                </p>
                <div className="flex items-center justify-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <p>OUI</p>
                    <Checkbox
                      checked={selectedDemat && isDemat}
                      onChange={() => {
                        selectDemat(true)
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <p>NON</p>
                    <Checkbox
                      checked={selectedDemat && !isDemat}
                      onChange={() => {
                        selectDemat(false)
                      }}
                    />
                  </div>
                </div>

                <button
                  onClick={() => createRenouvellement()}
                  className="p-2 text-white bg-purple-500 rounded-md w-96"
                >
                  Renouveller le contrat
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}
      <p className="font-semibold mt-10 text-xl">Prolongation</p>

      <div className=" w-full mt-6">
        <div className="flex hover:cursor-pointer items-center w-fit  border rounded-lg border-gray-300 bg-[#F7F9FC] ">
          <p
            onClick={() => {
              setFilter('Toutes')
              filterProlongations('Toutes')
            }}
            className={`${
              filter === 'Toutes' && 'bg-purple-400'
            }  border-r p-2 border-r-gray-300`}
          >
            Toutes
          </p>
          <p
            onClick={() => {
              setFilter('A prolonger')
              filterProlongations('A prolonger')
            }}
            className={`${
              filter === 'A prolonger' && 'bg-purple-400'
            }  border-r p-2 border-r-gray-300`}
          >
            A Prolonger
          </p>
          <p
            onClick={() => {
              setFilter('A renouveller')
              filterProlongations('A renouveller')
            }}
            className={`${
              filter === 'A renouveller' && 'bg-purple-400'
            }  border-r p-2 border-r-gray-300`}
          >
            A renouveller
          </p>
          <p
            onClick={() => {
              setFilter('A arreter')
              filterProlongations('A arreter')
            }}
            className={`${
              filter === 'A arreter' && 'bg-purple-400'
            }  border-r p-2 border-r-gray-300`}
          >
            A arreter
          </p>
        </div>
        <div className="text-left flex space-x-1 mb-6 h-10 items-center w-full justify-end pr-10">
          <div className="flex items-center justify-center space-x-2">
            <Input
              className={`w-80 rounded-md border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              onChange={(e) => {
                setQuery(e.target.value)
                /* if (e.target.value.length === 0) {
                  setProlongations(originalCovers)
                  setIsOriginal(true)
                } */
              }}
              style={{ outline: 'none' }}
            />
            <button
              onClick={() => findByText()}
              className="bg-purple-500 text-white p-2 rounded-md"
            >
              Rechercher
            </button>
          </div>
          <SearchIcon className="text-gray-500 text-3xl hover:cursor-pointer" />

          <img
            onClick={handleExportClick}
            src={xlsIcon}
            className="w-8 h-8 mx-2 hover:cursor-pointer"
          />
        </div>
      </div>

      <div className="my-6">
        <p className="font-semibold text-xl">
          {originalProlongations.length} contrats à prolonger
        </p>
      </div>

      <div className="flex   justify-between items-center mb-6">
        <div className="mt-10 flex items-start space-x-4">
          <div className=" flex space-x-2 items-center">
            <LeftOutlined
              onClick={() => {
                loadProlongations(weeks[currentWeekNumber - 1])
                setCurrentWeekNumber(currentWeekNumber - 1)
              }}
            />
            <p className="text-xl font-semibold">
              {formatJsDate(weeks[currentWeekNumber]?.firstDayOfWeek)}-
              {formatJsDate(weeks[currentWeekNumber]?.lastDayOfWeek)}
            </p>
            <RightOutlined
              onClick={() => {
                loadProlongations(weeks[currentWeekNumber + 1])
                setCurrentWeekNumber(currentWeekNumber + 1)
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex text-xs my-8 mx-6 items-center space-x-2">
        <p className="w-[12%] text-center">CONTRAT</p>
        <p className="w-[12%] text-center">AVENANT</p>
        <p className="w-[12%] text-center">CLIENT</p>
        <p className="w-[12%] text-center">INTERIMAIRE</p>
        <p className="w-[12%] text-center">DATES DU CONTRAT</p>
        <p className="w-[12%] text-center">DECISION CLIENT</p>
        <p className="w-[12%] text-center">STATUT</p>
        <p className="w-[12%] text-center">ACTION</p>
      </div>

      <div className="flex flex-col text-xs space-y-1">
        {prolongations.map((prolongation, idx) => {
          return (
            <div
              key={idx}
              className="flex rounded-md shadow-lg bg-white border border-gray-300 items-center p-1 my-2 space-x-2"
            >
              <p className="w-[12%] text-center">
                {prolongation.NumeroContrat}
              </p>
              <p className="w-[12%] text-center">
                {prolongation.NumeroAvenant}
              </p>
              <p className="w-[12%] text-center">
                {prolongation.RaisonSociale}
              </p>
              <p className="w-[12%] text-center">{prolongation.NomPrenom}</p>
              <div className="w-[12%] flex flex-col items-center justify-center">
                <p>{formatDate(prolongation.DateDebut)}</p>
                <p>{formatDate(prolongation.DateFin)}</p>
              </div>

              {prolongation.decisionProlongationClient && (
                <p className="w-[12%] text-center">
                  prolongation demandé jusqu'au{' '}
                  {formatJsDate(prolongation.dateProlongationContrat)}
                </p>
              )}
              {!prolongation.decisionProlongationClient && (
                <p className="w-[12%] text-center"></p>
              )}
              <p className="w-[12%] text-center">{prolongation.status}</p>
              <p className="w-[12%] flex flex-col items-center justify-center">
                <Popover
                  placement="bottom"
                  content={
                    <div className="flex flex-col capitalize text-base space-y-3">
                      <p
                        onClick={() => {
                          setIsRelance(true)
                          setProlongationsARelancer(
                            prolongations.filter(
                              (prol) => prol.IdClient === prolongation.IdClient,
                            ),
                          )
                        }}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        Relancer
                      </p>
                      <p
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        onClick={() => {
                          checkIsProlongation(prolongation.NumeroContrat)
                          setIsCreateProlongation(true)
                          setSelectedContract(prolongation)
                          setNewMotif(prolongation.TxtRecours)
                          setNewJustificatif(prolongation.TxtJustificatif)
                          setNbHeureHebdo(prolongation.NbHeureHebdo)
                          setNbJoursEssai(prolongation.NbJoursEssai)
                        }}
                      >
                        Prolonger
                      </p>
                      <p
                        onClick={() => {
                          setIsFinishDate(true)
                          setSelectedContract(prolongation)
                        }}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        Arreter
                      </p>
                    </div>
                  }
                  trigger="click"
                >
                  <Button>...</Button>
                </Popover>
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Prolongation
