import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'

const CreateContractReference = ({ contract, setContract }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <p className="font-semibold text-center text-3xl">
          Reference / horaires particuliers
        </p>

        <div className="flex flex-col mt-4 space-y-2">
          <p>Reference de la commande 1</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, Reference1: e.target.value })
            }
            value={contract.Reference1 ? contract.Reference1 : null}
            placeholder="Reference"
          />

          <p>Reference de la commande 2</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, Reference2: e.target.value })
            }
            value={contract.Reference2 ? contract.Reference2 : null}
            placeholder="Reference"
          />

          <p>Horaires particuliers</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, Horaires: e.target.value })
            }
            value={contract.Horaires ? contract.Horaires : null}
            placeholder="Horaires particuliers"
          />

          <p>Périodes non travaillées</p>
          <Input
            onChange={(e) =>
              setContract({ ...contract, PeriodeNonTrav: e.target.value })
            }
            value={contract.PeriodeNonTrav ? contract.PeriodeNonTrav : null}
            placeholder="Périodes non travaillées"
          />
        </div>
      </div>
      <div className='mt-4'>
        <p className='mb-4 text-lg'>Texte Facturation</p>
        <TextArea onChange={(e) =>
              setContract({ ...contract, TxtConsigneFacturation: e.target.value })
            }  placeholder='Texte facturation' className='rounded-md border border-gray-300' />
      </div>
    </div>
  )
}

export default CreateContractReference
